import ApproveRejectBtn from "../../Components/ApproveRejectBtn";
import ImagePlaceholder from "../../Components/ImagePlaceholder";
import { useDispatch } from "react-redux";
import Cross from "../../Assets/Images/Cross.svg";
import Tick from "../../Assets/Images/Tick.svg";
import Phone from "../../Assets/Images/Phone.svg";
import AgeIcon from "../../Assets/Images/ageIcon.svg";
import { surgeryReferLeadsUpdateStatusFetch } from "../../actions/surgeryReferLeads.action";
import { ageFormat, genderFormat } from "./utils";
import { useState } from "react";
// import { format } from "date-fns";


const SurgeryLeadCard = ({ data }) => {
    const dispatch = useDispatch();
    const [leadSlab, setLeadSlab] = useState("1");
    const handleStatus = (status) => {
        // const params = `?leadId=${data.id}&status=${status}`;
        const params = {
            id: data.id,
            status,
            leadSlab
            // dateTimeConverted: format(new Date(), 'yyyy-MM-dd HH:mm:ss')
        };
        dispatch(surgeryReferLeadsUpdateStatusFetch(params));
    }
    return (
        <>
            <div className="surgeryLeads-cards" >
                <div className="surgeryReferLeads-cardContainer">
                    <ImagePlaceholder name={data.firstName} style={{ backgroundColor: "#E6F6FF", color: "#004171" }} />
                    <div style={{ margin: "auto 0 auto 20px" }}>
                        <p className="surgeryReferLeads-cardTitle">
                            {data.firstName && data.firstName ? data.firstName + " " + data.lastName : "N/A"}
                        </p>
                        <div style={{ display: "flex", marginBottom: 5 }}>
                            <img src={Phone}
                                style={{ width: 20, height: 20 }}
                                alt="logo" />
                            <span className="surgeryReferLeads-phoneNumber">
                                {data.phoneNumber ? data.phoneNumber : "N/A"}
                            </span>
                        </div>
                        <div style={{ display: "flex", marginBottom: 5 }}>
                            <img src={AgeIcon}
                                style={{ width: 20, height: 20 }}
                                alt="logo" />
                            <span
                                className="surgeryReferLeads-age"
                            >{ageFormat(data.age)}</span>
                        </div>
                    </div>
                    <div style={{ marginLeft: "auto", color: "#63BE39" }}>
                        {genderFormat(data.gender)}
                    </div>
                </div>
                <div className="surgeryReferLeads-cardInfo"><strong>Surgery Details:</strong> {data.potentialLeadDetails || "N/A"}  </div>
                <div style={{ display: "flex", padding: 20, flexWrap: "wrap" }}>
                    <select className="surgeryReferLeads-slab" value={leadSlab} onChange={(e) => setLeadSlab(e.target.value)}>
                        <option value={1}>Upto 20,000</option>
                        <option value={2}>Between 20,000 to 50,000</option>
                        <option value={3}>Above 50,000</option>
                    </select>
                    <ApproveRejectBtn
                        onClick={() => handleStatus("Approved")}
                        variant={"approve"} icon={Tick} style={{ height: "fit-content", marginLeft: "auto" }} >
                        Approve
                    </ApproveRejectBtn>
                    <ApproveRejectBtn
                        onClick={() => handleStatus("Rejected")}
                        variant={"reject"} icon={Cross} style={{ height: "fit-content", marginLeft: 7 }} >
                        Reject
                    </ApproveRejectBtn>
                </div>
            </div>
        </>)
}
export default SurgeryLeadCard;
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { leadsByPanelistID, moreLeadsByPanelistID, panelistDetailsTypes, referredPanelistsByID } from "../types";
import {
    panelistDetailsSuccess, panelistDetailsError, referredPanelistsByIDSuccess,
    referredPanelistsByIDError,
    leadsByPanelistIDSuccess,
    leadsByPanelistIDError,
    moreLeadsByPanelistIDSuccess,
    moreLeadsByPanelistIDError
} from "../actions/panelist.action";
import { getLeadsByPanelistID, getPanelistDetails, getReferredPanelist } from "../services/api"

function* fetchPanelistDetails({ payload: id }) {
    try {
        const panelistDetails = yield call(getPanelistDetails, id);
        yield put(panelistDetailsSuccess(panelistDetails.data));
    } catch (e) {
        yield put(panelistDetailsError(e));
    }
}
function* fetchReferredPanelistsByID({ payload: id }) {
    try {
        const referredPanelists = yield call(getReferredPanelist, id);
        yield put(referredPanelistsByIDSuccess(referredPanelists.data));
    } catch (e) {
        yield put(referredPanelistsByIDError(e));
    }
}
function* fetchLeadsByPanelistID({ payload: { id, pageNo, pageSize } }) {
    // debugger
    try {
        const leadsByPanelistId = yield call(getLeadsByPanelistID, id, pageNo, pageSize);
        yield put(leadsByPanelistIDSuccess(leadsByPanelistId.data));
    } catch (e) {
        yield put(leadsByPanelistIDError(e));
    }
}

function* fetchMoreLeadsByPanelistID({ payload: { id, pageNo, pageSize } }) {
    try {
        const leadsByPanelistId = yield call(getLeadsByPanelistID, id, pageNo, pageSize);
        yield put(moreLeadsByPanelistIDSuccess(leadsByPanelistId.data));

    } catch (e) {
        console.log(e, "fetch more leads error")
        yield put(moreLeadsByPanelistIDError(e));
    }
}

export default function* saga() {
    yield takeEvery(panelistDetailsTypes.FETCH, fetchPanelistDetails);
    yield takeEvery(referredPanelistsByID.FETCH, fetchReferredPanelistsByID);
    yield takeLatest(leadsByPanelistID.FETCH, fetchLeadsByPanelistID);

    yield takeLatest(moreLeadsByPanelistID.FETCH, fetchMoreLeadsByPanelistID);


}

import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";

export function onConvertJsonToExcel(businessEventData, fileName) {

    let headerOfExcel = businessEventData[0]

    delete headerOfExcel?.pageLimit;
    delete headerOfExcel?.pageNo;
    delete headerOfExcel?.childId;
    delete headerOfExcel?.sequence;
    //  delete headerOfExcel?.dataCollectionDate;
    delete headerOfExcel?.totalRecords;


    console.log('businessEventDatahai', JSON.stringify(businessEventData))
    const keys = Object.keys(headerOfExcel)
    let header = [...keys];
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook?.sheet(0);
        const sheetData = getSheetData(businessEventData, header);
        const totalColumns = sheetData[0]?.length;
        sheet1.cell("A1").value(sheetData);
        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);
        sheet1.row(1).style("bold", true);
        sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
        range.style("border", true);
        return workbook.outputAsync().then((res) => {
            saveAs(res, fileName + new Date() + "file.xlsx");
        });
    });
}

function getSheetData(data, header) {
    var fields = Object?.keys(data[0]);
    var sheetData = data?.map(function (row) {
        return fields.map(function (fieldName) {
            if (fieldName)
                return row[fieldName] ? row[fieldName] : "";
        });
    });
    sheetData.unshift(header);
    return sheetData;
}

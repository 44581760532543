import React, { useEffect, useState } from 'react'
import { Link, NavLink, useOutletContext, useParams } from 'react-router-dom'
import SearchIcon from "../../../Assets/Images/Search.svg";

import "./index.css"

import { useSelector, useDispatch } from 'react-redux';
import {
    leadsByPanelistIDReset,
    // leadsByPanelistIDFetch,
    panelistDetailsFetch, referredPanelistsByIDFetch
} from '../../../actions/panelist.action';
import LeadsByPanelistContainer from './LeadsByPanelistContainer';
import ReferredPanelistContainer from './ReferredPanelistContainer';
import PanelistDetailsCard from './PanelistDetailsCard';
const PanelistProfile = () => {
    const { id } = useParams();
    const { activePanelistData } = useSelector((state) => state.panelistData)

    const [isLeadsActive, setLeadsActive] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(panelistDetailsFetch(id))
        dispatch(referredPanelistsByIDFetch(id))

        // dispatch(leadsByPanelistIDFetch({ id, pageNo: 1, pageSize: 10 }))
    }, [id, dispatch])
    useEffect(() => {

        return () => {
            dispatch(leadsByPanelistIDReset())
        }
    }, [dispatch])
    const { appContentRef } = useOutletContext()
    useEffect(() => {
        appContentRef?.current.scrollTo({ top: 0, behavior: 'smooth' });
    }, [appContentRef])
    return (
        <div>
            {
                activePanelistData.loading ?
                    <h1>Panelist Details Loading...</h1>
                    :

                    activePanelistData.data?.[id] ?
                        <>
                            <div className="panelistProfile-titleContainer"><NavLink to={"/admin/panelists"} style={{ textDecoration: "none", color: "unset", fontWeight: "500" }}>PanelPanelists </NavLink> &gt; {activePanelistData?.data[id]?.name ? activePanelistData.data[id].name : "N/A"}</div>
                            <div className='panelistProfileDetails-container'>
                                <PanelistDetailsCard panelistDetails={activePanelistData.data[id]} />

                                {/* filter */}
                                <div style={{ margin: "30px 0", display: "flex" }}>
                                    <div className={"profileSelectionBtn " + (isLeadsActive ? "profileSelectionBtnActive" : "")} onClick={() => {
                                        !isLeadsActive && setLeadsActive(true)
                                    }} style={{ borderRadius: "5px 0px 0px 5px" }}>
                                        Leads Added
                                    </div>
                                    <div
                                        className={"profileSelectionBtn " + (!isLeadsActive ? "profileSelectionBtnActive" : "")}
                                        style={{ borderRadius: "0px 5px 5px 0px" }}
                                        onClick={() => {
                                            isLeadsActive && setLeadsActive(false)
                                        }}
                                    >
                                        Panelist Referred
                                    </div>
                                </div>

                                {/* search bar */}
                                <div className="panelistProfile-searchContainer" style={{ marginBottom: 30 }}>
                                    <img
                                        src={SearchIcon}
                                        className="rewards-searchIcon"
                                        alt="logo"
                                    />
                                    <input
                                        className="panelistProfile-searchInput"
                                        placeholder="Search by Name"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </div>
                                {isLeadsActive ?
                                    <LeadsByPanelistContainer /> :
                                    <ReferredPanelistContainer />
                                }


                            </div>
                        </>
                        :
                        <div style={{ textAlign: "center" }}>
                            <h1>No Data Found</h1>
                            <Link to={"/admin/panelists"} className='btn btn-primary'>Go Back</Link>
                        </div>
            }
        </div>
    )
}

export default PanelistProfile
import React, { useState, useEffect } from 'react';
import "../../Components/ApproveRejectButton/ApproveModal.css";
import approvedsvg from "../../Assets/Images/approvedsvg.png";
import axios from 'axios';
import { urls } from "../../CommonUtils/config";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import member from "../../Assets/Images/member.svg";
import Phone from "../../Assets/Images/Phone.svg";
import line from "../../Assets/Images/line.svg";
import Horiz from "../../Assets/Images/Horiz.svg";
import clock from "../../Assets/Images/clock.svg";
import status from "../../Assets/Images/status.svg";
import nodatafound from "../../Assets/Images/nodatafound.svg";
import "../../CommonUtils/api";



import {
    clearDataFromStore,
    getDataFromStore,
} from "../../CommonUtils/commonUtils";
import "../../Screens/SwasthyaMitra/transactiondetails.css";
import crosst from "../../Assets/Images/crosst.svg";

const TransactionModal = ({ onClose, requestedAmount, swasthyaid, formatedData, swasthyaname }) => {
    const storedToken = localStorage.getItem("authToken");

    const [list, setList] = useState([]);
    const [newList, setnewList] = useState([]);
    const [paymentList, setpaymentList] = useState([]);
    const [paymentListDate, setpaymentListDate] = useState([]);

    const initialDate = dayjs().format('YYYYMM');
    const initialMonthName = dayjs().format('MMMM');
    const [monthName, setMonthName] = useState(initialMonthName);
    const [selectedDateFormatted, setSelectedDateFormatted] = useState(initialDate);
    const [selectedSection, setSelectedSection] = useState('Summary');
    const [modalMinHeight, setModalMinHeight] = useState("333px"); // Default min height
    const [activeFilter, setActiveFilter] = useState('Monthly');
    const [paymentsData, setPaymentsData] = useState([]);
    const [comissionData, setcomissionData] = useState([]);
    const [loading, setLoading] = useState(true);




    useEffect(() => {
        fetchData();
        Paymenttotal();
        fetchDataBasedOnDate(selectedDateFormatted);
        PaymenttotalwithDate(selectedDateFormatted);
        CommissiontotalwithDate(selectedDateFormatted);
    }, []);

    useEffect(() => {
        PaymenttotalwithDate(selectedDateFormatted);
        CommissiontotalwithDate(selectedDateFormatted);
        fetchDataBasedOnDate(selectedDateFormatted);
    }, [selectedDateFormatted]);

    const fetchData = async () => {
        try {
            const data = await axios.get(
                `${urls.engageBaseUrl}SMRequestForTakePayment/list?SWASTHUSERID=${swasthyaid}&PERIOD=0`, { headers: { Authorization: `Bearer ${storedToken}` } }
            );
            setList(data.data);
            setLoading(false);
        } catch (err) {
            console.log("error", err);
        }

    };
    console.log("Without Period ", list)

    const fetchDataBasedOnDate = async (date) => {
        try {
            const response = await axios.get(
                `${urls.engageBaseUrl}SMRequestForTakePayment/list?SWASTHUSERID=${swasthyaid}&PERIOD=${date}`, { headers: { Authorization: `Bearer ${storedToken}` } }
            );
            setnewList(response.data);
            setLoading(false);
        } catch (err) {
            console.log("error", err);
        }

    };
    console.log("With Period ", newList)

    const Paymenttotal = async (date) => {
        try {
            const response = await axios.get(
                `${urls.engageBaseUrl}SMPaymentDetails/list?SWASTHUSERID=${swasthyaid}&PERIOD=0`, { headers: { Authorization: `Bearer ${storedToken}` } }
            );
            setPaymentsData(response.data);
            setLoading(false);
        } catch (err) {
            console.log("error", err);
        }
    };

    const PaymenttotalwithDate = async (date) => {
        try {
            const response = await axios.get(
                `${urls.engageBaseUrl}SMPaymentDetails/list?SWASTHUSERID=${swasthyaid}&PERIOD=${date}`, { headers: { Authorization: `Bearer ${storedToken}` } }
            );
            setPaymentsData(response.data);
            setLoading(false);
        } catch (err) {
            console.log("error", err);
        }
    };

    if (activeFilter === 'Total') {
        console.log("Payment Data without Period", paymentsData);
    } else if (activeFilter === 'Monthly') {
        console.log("Payment Data with date", paymentsData);

    }


    const Commissiontotal = async (date) => {
        try {
            const response = await axios.get(
                `${urls.engageBaseUrl}SMEarningsDetailsByServices/list?SWASTHUSERID=${swasthyaid}&PERIOD=0`, { headers: { Authorization: `Bearer ${storedToken}` } }
            );
            setcomissionData(response.data);

        } catch (err) {
            console.log("error", err);
        }
    };

    console.log("Commission Data without Period", comissionData)

    const CommissiontotalwithDate = async (date) => {
        try {
            const response = await axios.get(
                `${urls.engageBaseUrl}SMEarningsDetailsByServices/list?SWASTHUSERID=${swasthyaid}&PERIOD=${date}`, { headers: { Authorization: `Bearer ${storedToken}` } }
            );
            setcomissionData(response.data);

        } catch (err) {
            console.log("error", err);
        }
    };

    console.log("Commission Data with Period", comissionData)




    const handleDateChange = (dayjsDate) => {
        const formatted = dayjsDate.format('YYYYMM');
        setSelectedDateFormatted(formatted);
        setMonthName(dayjsDate.format('MMMM'));
        console.log("Selected Date:", formatted);
    };

    dayjs.extend(customParseFormat);

    const handleDivClick = (section) => {
        setSelectedSection(section);
        switch (section) {
            case 'Summary':
                setModalMinHeight("333px");
                break;
            case 'Commission':
                setModalMinHeight("333px"); // Set desired height for this section
                break;
            case 'Payments':
                setModalMinHeight("333px"); // Set desired height for this section
                break;
            default:
                setModalMinHeight("333px");
        }
        console.log(section, "Section Clicked");
    };

    const handlefilterClick = (type) => {
        console.log(type);

        // Check if the selected filter type has changed
        if (type !== activeFilter) {
            setActiveFilter(type);

            if (type === 'Monthly') {

                PaymenttotalwithDate(selectedDateFormatted);
                CommissiontotalwithDate(selectedDateFormatted);
            } else if (type === 'Total') {
                Paymenttotal();
                Commissiontotal();
            }
        }
    };



    const activeStyle = {
        backgroundColor: '#00933F',
        color: '#FFFFFF',
        borderRadius: '30px'
    };

    function renderServiceSection(servicemasterType) {
        return comissionData.map((data, index) => {
            if (data.servicemaster === servicemasterType) {
                return (
                    <React.Fragment key={index}>
                        <div className='sectionheadingsdiv'>
                            <div className='commsionSubSection1'>{data.servicetype}</div>
                            <div className='horizontal'></div>
                            <div className='commsionSubSection2'>{data.commission}</div>
                            <div className='horizontal'></div>
                            <div className='commsionSubSection3'>{data.curebayamount}</div>
                            <div className='horizontal'></div>
                            <div className='commsionSubSection4'>{data.commissionamount}</div>
                        </div>
                    </React.Fragment>
                );
            } else {
                return null;
            }
        });
    }





    return (

        <div className='modal-containert'>
            <div className="approve-modalt" style={{ minHeight: modalMinHeight }}>
                <div className="modal-content">
                    <div className='Details'>

                        <div className='modaltitle'>View Details</div>
                        {
                            (selectedSection === 'Commission' || selectedSection === 'Payments') && (
                                <>
                                    <div
                                        className='filterSection1'
                                        onClick={() => handlefilterClick('Monthly')}
                                        style={activeFilter === 'Monthly' ? { ...activeStyle, borderTopRightRadius: '0', borderBottomRightRadius: '0' } : { borderTopRightRadius: '0', borderBottomRightRadius: '0' }}
                                    >
                                        Monthly
                                    </div>
                                    <div
                                        className='filterSection2'
                                        onClick={() => handlefilterClick('Total')}
                                        style={activeFilter === 'Total' ? { ...activeStyle, borderTopLeftRadius: '0', borderBottomLeftRadius: '0', borderLeft: 'none' } : { borderTopLeftRadius: '0', borderBottomLeftRadius: '0', borderLeft: 'none' }}
                                    >
                                        Total
                                    </div>

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            {activeFilter !== 'Total' && (
                                                <DatePicker
                                                    // label={'Select Month'}
                                                    views={['month', 'year']}
                                                    value={dayjs(`${selectedDateFormatted}01`, "YYYYMMDD")}
                                                    onChange={handleDateChange}
                                                />
                                            )}
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </>
                            )
                        }


                    </div>



                    <div className='Details'>

                        <div className={`section1 ${selectedSection === 'Summary' ? 'selected' : ''}`} onClick={() => handleDivClick('Summary')}>
                            Summary
                        </div>
                        <div
                            className={`section1 ${selectedSection === 'Commission' ? 'selected' : ''}`}
                            onClick={() => handleDivClick('Commission')}
                        >
                            Commission
                        </div>
                        <div
                            className={`section2 ${selectedSection === 'Payments' ? 'selected' : ''}`}
                            onClick={() => handleDivClick('Payments')}
                        >
                            Payments
                        </div>
                    </div>




                    {selectedSection === 'Summary' && (
                        <div className='swasthyamitradetails'>
                            <div className='block'>
                                <div className='Details'>
                                    <div className='modaltitle1'>Details: {swasthyaname}</div>
                                    <div className='requestamount'>Requested Amount :Rs {requestedAmount} </div>
                                </div>
                                {list.length > 0 ?
                                    list.map((data, i) => (
                                        <React.Fragment key={i}>
                                            <div className='detailspro'>
                                                <div className='headingtrans'>Total Earning:</div>
                                                <div className='subheadingtrans'> Rs. {data.totalearningamount}</div>
                                                <img src={line} className="Icontran" alt="logo" />
                                                <div className='headingtrans'>Total Redemmed:</div>
                                                <div className='subheadingtrans'> Rs. {data.totalpaymentamount}</div>
                                                <img src={line} className="Icontran" alt="logo" />
                                                <div className='headingtrans'>Total Pending:</div>
                                                <div className='subheadingtrans'> Rs. {data.totalpendingamount}</div>
                                                <img src={line} className="Icontran" alt="logo" />
                                                <div className='headingtrans'>Total Rejected:</div>
                                                <div className='subheadingtrans'> Rs. {data.totalrejectamount}</div>
                                            </div>
                                            <div className='detailsprosecond'>
                                                <div className='headingtrans'>Total Paybale:</div>
                                                <div className='subheadingtrans'> Rs. {data.remainingpayableamount}</div>
                                                <img src={line} className="Icontran" alt="logo" />
                                                <div className='headingtrans'>Total Redeemable:</div>
                                                <div className='subheadingtrans'> Rs. {data.takepayment}</div>

                                                {newList.length > 0 ?
                                                    newList.map((newData, newIndex) => (
                                                        <React.Fragment key={newIndex}>
                                                            <img src={line} className="Icontran" alt="logo" />
                                                            <div className='headingtrans'>Earnings ({monthName}) :</div>
                                                            <div className='subheadingtrans'> Rs. {newData.totalearningamount}</div>
                                                        </React.Fragment>
                                                    ))
                                                    :
                                                    <div className="no-data-message">No Data Found</div>
                                                }

                                            </div>
                                            <div className='detailsprothird'>
                                                {newList.map((newData, newIndex) => (
                                                    <React.Fragment key={newIndex}>
                                                        <div className='headingtrans'> Reedemed ({monthName}):</div>
                                                        <div className='subheadingtrans'> Rs. {newData.totalpaymentamount}</div>
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </React.Fragment>
                                    ))
                                    :
                                    <div className="no-data-message">No Data Found</div>
                                }

                            </div>
                        </div>
                    )}
                    {selectedSection === 'Commission' && (
                        <div className='maindiv'>
                            {comissionData && comissionData.length > 0 ? (
                                <div className='comissionTable'>
                                    <div className='headingsdiv'>
                                        <div className='commsionSection1'>Services Provided</div>
                                        <div className='commsionSection2'>Commission (%)</div>
                                        <div className='commsionSection3'>Total Transaction</div>
                                        <div className='commsionSection4'>Commission Earned</div>
                                    </div>

                                    <h1 className='sectionHeading'>APPOINTMENT</h1>
                                    {renderServiceSection("APPOINTMENT")}
                                    <h1 className='sectionHeading'>MEMBERSHIP</h1>
                                    {renderServiceSection("MEMBERSHIP")}
                                    <h1 className='sectionHeading'>DIAGNOSTIC</h1>
                                    {renderServiceSection("DIAGNOSTIC")}
                                    <h1 className='sectionHeading'>HOSPITAL</h1>
                                    {renderServiceSection("HOSPITAL")}
                                    <h1 className='sectionHeading'>PHARMACY</h1>
                                    {renderServiceSection("PHARMACY")}
                                    <h1 className='sectionHeading'>REWARDS</h1>
                                    {renderServiceSection("REWARDS")}
                                    <h1 className='sectionHeading'>TRANSACTIONS</h1>
                                    {renderServiceSection("PAST TRANSACTIONS")}
                                </div>
                            ) : (
                                <div className="no-data-message">No Data Found</div>
                            )}
                        </div>
                    )}


                    {
                        selectedSection === 'Payments' ? (
                            paymentsData.length > 0 ?
                                paymentsData.map((data, i) => (
                                    <div className='swasthyamitradetails' key={i}>
                                        <div className='Details'>
                                            <div className='modaltitle'>Payment Details</div>
                                            <div className='requestdate'>
                                                Date: {data.requestdatetime.split(' ')[0]}
                                            </div>
                                        </div>
                                        <div className='detailspro'>
                                            <img src={clock} className="clock" alt="logo" />
                                            <div className='headingtrans'>Time:</div>
                                            <div className='subheadingtrans'>{data.requestdatetime.split(' ')[1] + ' ' + data.requestdatetime.split(' ')[2]}</div>
                                            <img src={line} className="Icontran" alt="logo" />
                                            <img src={status} className="clock" alt="logo" />
                                            <div className='headingtrans'>Status:</div>
                                            <div
                                                className='subheadingtrans'
                                                style={{
                                                    color: data.validatestatus === 'Pending' ? '#ECAC09' :
                                                        data.validatestatus === 'Credited' ? '#00933F' :
                                                            data.validatestatus === 'Rejected' ? '#DC0A0A' :
                                                                data.validatestatus === 'Approved' ? '#1B1160' :
                                                                    'Black'
                                                }}
                                            >
                                                {data.validatestatus}
                                            </div>
                                            <div className='statusti'>
                                                <div className='headingtrans'>Price:</div>
                                                <div className='subheadingtrans'>Rs. {data.amount}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                :
                                <img src={nodatafound} className="nodatafound" alt="logo" />
                        )
                            : null
                    }
                    <button className='closingbutton' onClick={() => onClose(false)}> Close
                    </button>

                </div>

            </div>

        </div>
    );
};

export default TransactionModal;

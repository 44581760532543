import { assignmentDetailsNotReviewedTypes, assignmentDetailsReviewedTypes, assignmentDetailsTypes } from "../types";
const initialState = {
    data: {},
    loading: false,
    error: "",
    reviewed: {
        data: [],
        loading: false,
        error: "",
        dataStatus: false
    },
    notReviewed: {
        data: [],
        loading: false,
        error: "",
        dataStatus: false
    }
}
const reducer = (state = initialState, action) => {

    const { type, payload } = action;
    const { FETCH, SUCCESS, ERROR, RESET, UPDATE } = assignmentDetailsTypes;
    switch (type) {
        case FETCH:
        case UPDATE:
            return { ...state, loading: true }
        case SUCCESS:
            return {
                ...state, data: payload, loading: false, error: "",
                reviewed: { ...initialState.reviewed },
                notReviewed: { ...initialState.notReviewed }
            }
        case ERROR:
            return { ...state, loading: false, error: payload }

        // reviewed assignments
        case assignmentDetailsReviewedTypes.FETCH:
            return { ...state, reviewed: { ...state.reviewed, loading: true, error: "" } }
        case assignmentDetailsReviewedTypes.SUCCESS:
            return { ...state, reviewed: { data: payload, loading: false, error: "", dataStatus: true } }
        case assignmentDetailsReviewedTypes.ERROR:
            return { ...state, reviewed: { ...state.reviewed, loading: false, error: payload } }

        // NOT reviewed assignments
        case assignmentDetailsNotReviewedTypes.FETCH:
            return { ...state, notReviewed: { ...state.notReviewed, loading: true, error: "" } }
        case assignmentDetailsNotReviewedTypes.SUCCESS:
            return { ...state, notReviewed: { data: payload, loading: false, error: "", dataStatus: true } }
        case assignmentDetailsNotReviewedTypes.ERROR:
            return { ...state, notReviewed: { ...state.notReviewed, loading: false, error: payload } }


        case RESET:
            return initialState;
        default:
            return state;
    }
}

export default reducer;
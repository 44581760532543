import { apiTypeCreator } from "../utils";
export const assignmentsTypes = apiTypeCreator("ASSIGNMENTS");
export const assignmentDetailsTypes = apiTypeCreator("ASSIGNMENT_DETAILS");

export const assignmentDetailsReviewedTypes = apiTypeCreator("ASSIGNMENT_DETAILS_REVIEWED");
export const assignmentDetailsNotReviewedTypes = apiTypeCreator("ASSIGNMENT_DETAILS_NOT_REVIEWED");

export const recentPanelistTypes = apiTypeCreator("RECENT_PANELIST");
export const activePanelistTypes = apiTypeCreator("ACTIVE_PANELIST");
export const userLoginTypes = apiTypeCreator("USER_LOGIN");
export const userBearerTokenTypes = apiTypeCreator("BEARER_TOKEN");
export const updatePanelistStatusTypes = apiTypeCreator("APPROVE_REJECT_PANELIST");
export const activeInActivePanelistStatusTypes = apiTypeCreator("ACTIVE_INACTIVE_PANELIST");

export const addActiveInActivePanelistStatusTypes = { ...apiTypeCreator("ACTIVE_INACTIVE_PANELIST"), UPDATE: "UPDATE_ACTIVE_INACTIVE_PANELIST", RESPONSE: "RES_ACTIVE_INACTIVE_PANELIST" };


export const addAssignmentTypes = { ...apiTypeCreator("ADD_ASSIGNMENT"), UPDATE: "UPDATE_ADD_ASSIGNEMENT", RESPONSE: "RES_ADD_ASSIGNMENT" };

export const activeRewardsTypes = apiTypeCreator("ACTIVE_REWARDS");
export const inactiveRewardsTypes = apiTypeCreator("INACTIVE_REWARDS");

export const panelistDetailsTypes = apiTypeCreator("PANELIST_DETAILS");

export const referredPanelistsByID = apiTypeCreator("REFERRED_PANELISTS_BYID");
export const leadsByPanelistID = apiTypeCreator("PANELIST_LEADS")
export const moreLeadsByPanelistID = {
    ...apiTypeCreator("PANELIST_MORE_LEADS"),
    // UPDATE_PAGE_NUMBER: "UPDATE_PAGE_NUMBER_PANELIST_MORE_LEADS",
    UPDATE_HAS_MORE: "UPDATE_HAS_MORE_PANELIST_MORE_LEADS",
};
export const cureBayContentTypes = apiTypeCreator("CUREBAY_CONTENT");
export const dashboardTypes = apiTypeCreator("DASHBOARD");
export const dashboardPanelistTypes = apiTypeCreator("DASHBOARD_PANELIST");
export const dashboardLeadsTypes = apiTypeCreator("DASHBOARD_LEADS");
export const dashboardAssignmentsTypes = apiTypeCreator("DASHBOARD_ASSIGNMENTS");
export const dashboardPaymentsTypes = apiTypeCreator("DASHBOARD_PAYMENTS");
export const dashboardTotalDataTypes = apiTypeCreator("DASHBOARD_TOTAL_DATA");

export const promotionListTypes = apiTypeCreator("PROMOTION_LIST");
export const dataCollectionListTypes = apiTypeCreator("DATA_COLLECTION_LIST");
export const dataCollectionDetailsTypes = apiTypeCreator("DATA_COLLECTION_DETAILS");
export const eClinicListTypes = apiTypeCreator("E_CLINIC_LIST");
export const cureBayContentListTypes = apiTypeCreator("CUREBAY_CONTENT_LIST");

export const surgeryReferLeadsTypes = apiTypeCreator("SURGERY_REFER_LEADS");
export const surgeryReferLeadsUpdateTypes = apiTypeCreator("SURGERY_REFER_LEADS_UPDATE_STATUS");



import React, { useEffect, useState } from 'react'
import SearchIcon from '../../Assets/Images/Search.svg';
import editIcon from '../../Assets/Images/edit.svg';
import CloseIcon from '../../Assets/Images/closeIcon.svg';
import UnCheckedRadioButton from '../../Assets/Images/UnCheckedRadioButton.svg';
import CheckedRadioButton from '../../Assets/Images/CheckedRadioButton.svg';

import "./index.css"
import { useDispatch, useSelector } from 'react-redux';
import { activeRewardsFetch, inactiveRewardsFetch } from '../../actions/rewards.action';
import { formatDate } from '../../CommonUtils/commonUtils';
import Modal from '../../Components/Modal';
import Tabel from '../../Components/Tabel';

const Rewards = () => {
    const [isCurrentSellected, setCurrentSellected] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    // const [rewardsPoint, setRewardsPoint] = useState("0")
    // const [modalDescription, setModalDescription] = useState("")

    // const [isPointModalVisible, setEditModalVisible] = useState(false);
    // const [isEndDateModal, setEndDateModal] = useState(false)
    // const [isDescriptionModal, setDescriptionModal] = useState(false)
    const [isActionModal, setActionModal] = useState(false);

    const handleActionModal = () => {
        setActionModal(!isActionModal)
    }

    // const handlePointModal = () => {
    //     setEditModalVisible(!isPointModalVisible)
    // }
    // const handleEndDateModal = () => {
    //     setEndDateModal(!isEndDateModal)
    // }
    // const handleDescriptionModal = () => {
    //     setDescriptionModal(!isDescriptionModal);
    // }

    const handleSearch = (e) => {
        setSearchQuery(e.target.value)
    }
    const rewards = useSelector((state) => state.rewards)
    const dispatch = useDispatch();

    useEffect(() => {
        if (isCurrentSellected && rewards.activeRewards?.length === 0) {
            dispatch(activeRewardsFetch());
        } else if (!isCurrentSellected && rewards.inactiveRewards?.length === 0) {
            dispatch(inactiveRewardsFetch());
        }
    }, [isCurrentSellected, dispatch, rewards.activeRewards.length, rewards.inactiveRewards.length])
    return (
        <>
            <div>
                <div className='rewards-titleContainer'>
                    Rewards
                </div>
                <div className='rewards-filterContainer'>
                    <div className='rewards-searchContainer'>
                        <img src={SearchIcon} className="rewards-searchIcon" alt="logo" />
                        <input className="rewards-searchInput" placeholder='Search your..' onChange={handleSearch} value={searchQuery} />
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div className='rewards-filterBlocks' onClick={() => {
                        !isCurrentSellected && setCurrentSellected(true)
                    }} >
                        <img className='rewards-radioButton' src={isCurrentSellected ? CheckedRadioButton : UnCheckedRadioButton} alt="Rewards status" />
                        <p className='rewards-radioButtonText'>Active Rewards</p>
                    </div>
                    <div className='rewards-filterBlocks' onClick={() => {
                        isCurrentSellected && setCurrentSellected(false)
                    }}>
                        <img className='rewards-radioButton' src={isCurrentSellected ? UnCheckedRadioButton : CheckedRadioButton} alt="Rewards status" />
                        <p className='rewards-radioButtonText'>Inactive Rewards</p>
                    </div>
                </div>

                <Tabel style={{ margin: "30px 0" }}>
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Description</th>
                            <th>Rewards</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            {/* <th>Action</th> */}
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "#fff" }}>
                        {rewards.loading
                            ?
                            <tr><td colSpan={6}><h2>Loading...</h2></td></tr> :
                            isCurrentSellected ?
                                rewards.activeRewards?.length === 0 ?
                                    <tr>
                                        <td colSpan={6}>
                                            <h2>No Active Data Found</h2>
                                        </td>
                                    </tr> :
                                    rewards.activeRewards.filter((reward) => {
                                        if (searchQuery) {
                                            return reward.category.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
                                        }
                                        return reward
                                    }).map((reward) => (
                                        <tr key={reward.id}>
                                            <td>{reward.category}</td>
                                            <td >
                                                {reward.description}
                                                {/* <div style={{ display: "flex", padding: "0 15px" }}>
                                                    <div>
                                                        <img className='reward-dataRating'
                                                            onClick={handleDescriptionModal}
                                                            src={editIcon} />
                                                    </div>
                                                    <div>{reward.description}</div>
                                                </div> */}
                                            </td>
                                            <td style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                {reward.rewardpoint < 10 ? "0" + reward.rewardpoint : reward.rewardpoint}
                                            </td>
                                            <td>
                                                {formatDate(new Date(reward.startDate))}
                                            </td>
                                            <td>
                                                {/* <img className='reward-dataRating'
                                                    onClick={handleEndDateModal}
                                                    src={editIcon} /> */}
                                                {formatDate(new Date(reward.endDate))}
                                            </td>

                                            {/* <td>
                                                <img
                                                    style={{ cursor: "pointer" }}
                                                    onClick={handleActionModal}
                                                    src={editIcon} />
                                            </td> */}
                                        </tr>
                                    )) :
                                rewards.inactiveRewards.length === 0 ?
                                    <tr>
                                        <td colSpan={6}>
                                            <h2>No Inactive Data Found</h2>
                                        </td>
                                    </tr>
                                    :
                                    rewards.inactiveRewards.filter((reward) => {
                                        if (searchQuery) {
                                            return reward.category.toLocaleLowerCase()
                                                .includes(searchQuery.toLocaleLowerCase())
                                        }
                                        return reward
                                    }).map((reward) => (
                                        <tr key={reward.id}>
                                            <td>{reward.category}</td>
                                            <td>
                                                {/* <img className='reward-dataRating'
                                                    onClick={handleDescriptionModal}
                                                    src={editIcon} /> */}
                                                {reward.description}
                                            </td>
                                            <td style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                {/* <img className='reward-dataRating'
                                                    onClick={handlePointModal}
                                                    src={editIcon} /> */}

                                                {reward.rewardpoint < 10 ? "0" + reward.rewardpoint : reward.rewardpoint}
                                            </td>
                                            <td>
                                                {formatDate(new Date(reward.startDate))}</td>
                                            <td>
                                                {/* <img className='reward-dataRating'
                                                    onClick={handleEndDateModal}
                                                    src={editIcon} /> */}
                                                {formatDate(new Date(reward.endDate))}</td>
                                            {/* <td>
                                                <img
                                                    className='reward-editIcon'
                                                    onClick={handleActionModal}
                                                    src={editIcon}
                                                    alt="Edit"
                                                />

                                            </td> */}
                                        </tr>
                                    ))
                        }
                    </tbody>
                </Tabel>

            </div>
            {/* action modal */}
            <Modal show={isActionModal} onHide={handleActionModal}>
                <div className='rewardsModal-body'>
                    <img
                        className="rewards-modal-close-btn"
                        onClick={handleActionModal}
                        src={CloseIcon}
                        alt="Close modal"
                    />

                    <Tabel style={{ margin: "40px 0 20px" }}>
                        <thead>
                            <tr>
                                <th>Category</th>
                                <th>Description</th>
                                <th>Rewards</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rewards.loading ? <tr><td colSpan={6}>loading</td></tr> :
                                isCurrentSellected ?
                                    rewards.activeRewards?.length === 0 ?
                                        <tr>
                                            <td colSpan={6}>
                                                <h2>No Active Data Found</h2>
                                            </td>
                                        </tr> :
                                        rewards.activeRewards.filter((reward) => {
                                            if (searchQuery) {
                                                return reward.category.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
                                            }
                                            return reward
                                        }).map((reward) => (
                                            <tr key={reward.id}>
                                                <td>{reward.category}</td>
                                                <td >
                                                    <input type={"text"} className="rewardModal-input" placeholder='Type..' />
                                                    {/* {reward.description} */}
                                                    {/* <div style={{ display: "flex", padding: "0 15px" }}>
                                                    <div>
                                                        <img className='reward-dataRating'
                                                            onClick={handleDescriptionModal}
                                                            src={editIcon} />
                                                    </div>
                                                    <div>{reward.description}</div>
                                                </div> */}
                                                </td>
                                                <td style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <input type={"text"} className="rewardModal-input" placeholder='Type..' />
                                                </td>
                                                <td>
                                                    {formatDate(new Date(reward.startDate))}
                                                </td>
                                                <td>
                                                    <input type={"text"} onBlur={(e) => e.target.type = "text"} onFocus={(e) => e.target.type = "date"} className="rewardModal-input" placeholder='Type..' />
                                                </td>

                                                <td>
                                                    <img
                                                        className='reward-editIcon'
                                                        onClick={handleActionModal}
                                                        src={editIcon}
                                                        alt='Edit Button'
                                                    />
                                                </td>
                                            </tr>
                                        )) :
                                    rewards.inactiveRewards.length === 0 ?
                                        <tr>
                                            <td colSpan={6}>
                                                <h2>No Inactive Data Found</h2>
                                            </td>
                                        </tr>
                                        :
                                        rewards.inactiveRewards.filter((reward) => {
                                            if (searchQuery) {
                                                return reward.category.toLocaleLowerCase()
                                                    .includes(searchQuery.toLocaleLowerCase())
                                            }
                                            return reward
                                        }).map((reward) => (
                                            <tr key={reward.id}>
                                                <td>{reward.category}</td>
                                                <td>

                                                    <input type={"text"} className="rewardModal-input" placeholder='Type..' />
                                                    {/* {reward.description} */}
                                                </td>
                                                <td style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                                    <input type={"text"} className="rewardModal-input" placeholder='Type..' />
                                                    {/* {reward.rewardpoint < 10 ? "0" + reward.rewardpoint : reward.rewardpoint} */}
                                                </td>
                                                <td>
                                                    {formatDate(new Date(reward.startDate))}</td>
                                                <td>
                                                    <input type={"text"} onBlur={(e) => e.target.type = "text"} onFocus={(e) => e.target.type = "date"} className="rewardModal-input" placeholder='Type..' />
                                                    {/* {formatDate(new Date(reward.endDate))} */}
                                                </td>

                                                <td>
                                                    {/* <img
                                                        className='reward-editIcon'
                                                        onClick={handleActionModal}
                                                        src={editIcon} /> */}
                                                    Save
                                                </td>
                                            </tr>
                                        ))
                            }
                        </tbody>
                    </Tabel>
                    <button className='rewardsModal-submitBtn' onClick={handleActionModal}>Save</button>
                </div>
            </Modal>
            {/* reward point modal */}
            {/* <Modal show={isPointModalVisible} onHide={handlePointModal}>
                <div className='rewardsModal-body'>
                    <img
                        className="rewards-modal-close-btn"
                        onClick={handlePointModal}
                        src={CloseIcon}
                    />

                    <h1 className='rewardsModal-title'>Change Reward points</h1>
                    <h2 className='rewardsModal-subtitle'>Type Number</h2>
                    <input className='rewardsModal-input' type={"number"} value={rewardsPoint} onChange={(e) => {
                        setRewardsPoint(e.target.value)
                    }} style={{ textAlign: "center" }} />
                    <button className='rewardsModal-submitBtn'>Done</button>
                </div>
            </Modal> */}

            {/* end date modal */}
            {/* <Modal show={isEndDateModal} onHide={handleEndDateModal}>
                <div className='rewardsModal-body'>
                    <img
                        className="rewards-modal-close-btn"
                        onClick={handleEndDateModal}
                        src={CloseIcon}
                    />

                    <h1 className='rewardsModal-title'>Change End Date</h1>
                    <input className='rewardsModal-input' type="text" placeholder='Choose Date' onFocus={(e) => {
                        e.target.type = "date";
                    }} />
                    <button className='rewardsModal-submitBtn'>Done</button>
                </div>
            </Modal> */}

            {/* Description modal */}
            {/* <Modal show={isDescriptionModal} onHide={handleDescriptionModal}>
                <div className='rewardsModal-body'>
                    <img
                        className="rewards-modal-close-btn"
                        onClick={handleDescriptionModal}
                        src={CloseIcon}
                    />

                    <h1 className='rewardsModal-title'>Type Description</h1>
                    <input className='rewardsModal-input' type="text" placeholder='Type your..' value={modalDescription} onChange={(e) => { setModalDescription(e.target.value) }} style={{ width: 308 }} />
                    <button className='rewardsModal-submitBtn'>Done</button>
                </div>
            </Modal> */}
        </ >

    )

}



export default Rewards
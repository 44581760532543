import { addAssignmentTypes } from "../types";
const initialState = {
    data: {
        description: "",
        startDate: "",
        endDate: "",
        name: "Amantya Tech",
        status: "updateDone"
    },
    loading: false,
    error: "",
    addAssignmentStatus: ""
}
const reducer = (state = initialState, action) => {

    const { type, payload } = action;
    const { FETCH, SUCCESS, ERROR, RESET, UPDATE, RESPONSE } = addAssignmentTypes;
    switch (type) {
        case FETCH:
            return { ...state, loading: true }
        case SUCCESS:
            return { data: payload, loading: false, error: "" }
        case ERROR:
            return { ...state, loading: false, error: payload }
        case RESET:
            return initialState;
        case UPDATE:
            return { ...state, data: { ...state.data, ...payload } }
        case RESPONSE:
            return { ...state, loading: false, addAssignmentStatus: payload }
        default:
            return state;
    }
}

export default reducer;
import React, { useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import imagePlaceholder from "../../../Assets/Images/imagePlaceholder.svg";
import { curebayFileValidaton } from '../../../CommonUtils/config';

const DropFiles = ({ onChange, files }) => {

    const onDrop = useCallback(acceptedFiles => {
        onChange(acceptedFiles)
    }, [onChange])

    // configuration
    const { fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/jpeg': [".gif", ".jpg", ".jpeg", ".jfif", ".pjpeg", ".pjp", ".png", ".svg"],
            'video/mp4': []
        },
        multiple: false,
        validator: validation,
        onDrop
    })

    // error messages
    const fileRejectionItems = fileRejections.map(({ file, errors }) => {

        return (<div className='image-error' key={file.path}>
            <strong> File path :</strong> {file.path} - {file.size} bytes
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </div>)
    });

    useEffect(() => {
        return () => {
            if (files) {
                URL.revokeObjectURL(files.preview)
            }
        }
    }, [files])
    return (
        <div {...getRootProps()} className="curebay-imgInput">
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <>
                        <p>Drop the files here ...</p>
                    </>
                    :
                    <>
                        {files ?
                            files.type === "video/mp4" ? <video src={files.preview} style={{ maxWidth: "350px" }} controls /> : <img className='curebay-imgInputSelected' src={files.preview}
                                alt="media preview"
                            />
                            // files.map((file, i) => {
                            //     if (file.type === "video/mp4") {
                            //         return <video key={file.lastModified + i} src={file.preview} style={{ maxWidth: "350px" }} controls>
                            //         </video>
                            //     } else {
                            //         return <img key={file.lastModified + i} className='curebay-imgInputSelected' src={file.preview} />
                            //     }
                            // })
                            :
                            <>
                                <img src={imagePlaceholder} className="curebay-imgInputIcon" alt="Media Placeholder" />
                                <p>Browse to upload Image and Videos</p>
                            </>
                        }
                    </>
            }
            {fileRejectionItems}
        </div>
    )
}
export default DropFiles

const validation = (file) => {
    // const maxLength = 40;

    const { maxFileSize, code, message } = curebayFileValidaton
    // console.log(file, "error")

    // if (file.name?.length > maxLength) {
    //     return {
    //         code: "name-too-large",
    //         message: `Name is larger than ${maxLength} characters`
    //     };
    // }

    if (file.size > maxFileSize * 1000000) {
        return {
            code,
            message
        };
    }
    return null
}
export const genderFormat = (gender) => {
    if (gender === "M") {
        return "Male";
    } else if (gender === "F") {
        return "Female";
    }
    return "N/A";
}

export const ageFormat = (age) => {
    if (age === null || isNaN(age)) {
        return "N/A";
    }
    return (parseInt(age) > 1) ? age + " yrs" : age + " yr";
}
import { assignmentDetailsNotReviewedTypes, assignmentDetailsReviewedTypes, assignmentDetailsTypes } from "../types";
const { FETCH, SUCCESS, ERROR, RESET, UPDATE } = assignmentDetailsTypes;

export function assignmentDetailsFetch(id) {
    return {
        type: FETCH,
        payload: id
    }
}
export function assignmentDetailsSuccess(val) {
    return {
        type: SUCCESS,
        payload: val
    }
}
export function assignmentDetailsError(val) {
    return {
        type: ERROR,
        payload: val
    }
}
export function assignmentDetailsReset() {
    return {
        type: RESET
    }
}
export function updateAssignmentStatus(payload) {
    return {
        type: UPDATE,
        payload
    }
}

// assignmentDetails reviewed
export function assignmentDetailsReviewedFetch(data) {
    return {
        type: assignmentDetailsReviewedTypes.FETCH,
        payload: data
    }
}
export function assignmentDetailsReviewedSuccess(val) {
    return {
        type: assignmentDetailsReviewedTypes.SUCCESS,
        payload: val
    }
}
export function assignmentDetailsReviewedError(val) {
    return {
        type: assignmentDetailsReviewedTypes.ERROR,
        payload: val
    }
}
export function assignmentDetailsReviewedReset() {
    return {
        type: assignmentDetailsReviewedTypes.RESET,
    }
}

// assignmentDetails Not reviewed
export function assignmentDetailsNotReviewedFetch(data) {
    return {
        type: assignmentDetailsNotReviewedTypes.FETCH,
        payload: data
    }
}
export function assignmentDetailsNotReviewedSuccess(val) {
    return {
        type: assignmentDetailsNotReviewedTypes.SUCCESS,
        payload: val
    }
}
export function assignmentDetailsNotReviewedError(val) {
    return {
        type: assignmentDetailsNotReviewedTypes.ERROR,
        payload: val
    }
}
export function assignmentDetailsNotReviewedReset() {
    return {
        type: assignmentDetailsNotReviewedTypes.RESET,
    }
}


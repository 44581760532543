import { call, put, takeEvery } from "redux-saga/effects";
import { cureBayContentTypes, cureBayContentListTypes } from "../types";
import { cureBayContentSuccess, cureBayContentError, cureBayContentListSuccess, cureBayContentListError } from "../actions/cureBayContent.action";
import { addCureBayContent, getCureBayContentList } from "../services/api"

function* postCureBayContent({ payload }) {
    try {
        yield call(addCureBayContent, payload);
        yield put(cureBayContentSuccess(true));
    } catch (e) {
        yield put(cureBayContentError(e));
    }
}
function* getCureBayContentListSaga() {
    try {
        const cureBayContentListRes = yield call(getCureBayContentList);
        yield put(cureBayContentListSuccess(cureBayContentListRes.data));
    } catch (e) {
        yield put(cureBayContentListError(e));
    }
}

export default function* saga() {
    yield takeEvery(cureBayContentTypes.FETCH, postCureBayContent);
    yield takeEvery(cureBayContentListTypes.FETCH, getCureBayContentListSaga);
}

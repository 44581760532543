import React from 'react';
import { NavLink } from "react-router-dom";
import './index.css';
import arr from "../../Assets/Images/arr.svg"

const sideBarOption = [
  {
    key: 6, value: 'Dashboard', nevLink: "/admin/dashboard"
  },
  {
    key: 1, value: 'Panelists', nevLink: "/admin/panelists"
  },
  {
    key: 2, value: 'Assignments', nevLink: "/admin/assignments"

  },

  {
    key: 4, value: 'CureBay Content', nevLink: "/admin/curebayContent"

  },

  // {
  //   key: 5, value: 'Potential Lead', nevLink: "/admin/potential-lead"

  // },
  {
    key: 3, value: 'Rewards', nevLink: "/admin/rewards"

  },
  // {
  //   key: 8, value: 'Promotion List', nevLink: "/admin/promotionList"

  // },
  {
    key: 9, value: 'Surgery Refer Leads', nevLink: "/admin/surgeryReferLeads"

  },

  {
    key: 7, value: 'Data Collection Info', nevLink: "/admin/dataCollectionInfo"

  },

  {
    key: 8, value: 'SM Payment', nevLink: "/admin/smpage"
  },
  {
    key: 10, value: 'SM Agreements', nevLink: "/admin/smagreement"
  }

]


export default function AppSidebar() {
  return (
    <div className='sidebarContainer'>
      {sideBarOption.map(({ nevLink, value }, index) => {
        return <NavLink key={index} to={nevLink}
          className={({ isActive }) => isActive ? "nevBlock-active nevBlock" : "nevBlock"}>
          {value} <img src={arr} style={{ marginLeft: "auto" }} alt={"Goto icon"} />
        </NavLink>
      })}


      {/* <NavLink to={"assignments"}
        className={() => {
          const currentRoute = pathname.split("/")[2];
          console.log(currentRoute, "currentRoute")
          return ["assignments", "assignmentDetails", "addNew"].includes(currentRoute) ? "nevBlock-active nevBlock" : "nevBlock"
        }}
      >new assignment</NavLink> */}

    </div >
  );
}

import React, { useState } from 'react'
import SearchIcon from "../../Assets/Images/Search.svg";
import "./index.css";
import { useSelector } from 'react-redux';
import SurgeryLeadsContainer from './SurgeryLeadsContainer';




const SurgeryReferLeads = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const handleSearch = (e) => {
        setSearchQuery(e.target.value)
    }
    const { loading, data: surgeryLeads } = useSelector((store) => store.surgeryReferLeads.leadList);
    return (
        <>
            <div className='surgeryReferLeads-titleContainer'>
                Panelists
            </div>
            <div className='surgeryReferLeads-filterContainer'>
                <div className='surgeryReferLeads-searchContainer'>
                    <img src={SearchIcon} className="surgeryReferLeads-searchIcon" alt="logo" />
                    <input className="surgeryReferLeads-searchInput" placeholder='Search by Name'
                        onChange={handleSearch} value={searchQuery}
                    />
                </div>
            </div>
            <SurgeryLeadsContainer loading={loading}
                surgeryLeads={surgeryLeads?.filter(({ firstName, lastName }) => (firstName + " " + lastName).toLowerCase().includes(searchQuery.toLowerCase()))} />
        </>
    )
}


export default SurgeryReferLeads
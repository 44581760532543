import React, { useState, useEffect } from "react";
import "./index.css";
import UnCheckedRadioButton from "../../Assets/Images/UnCheckedRadioButton.svg";
import CheckedRadioButton from "../../Assets/Images/CheckedRadioButton.svg";
import AddIconWhite from "../../Assets/Images/AddIconWhite.svg";
import ArrowRight from "../../Assets/Images/ArrowRight.svg";
import { NavLink } from "react-router-dom";
import DropDownComponent from "../../Components/DropDown";
import { useDispatch, useSelector } from "react-redux";
import { assignmentsFetch } from "../../actions/assignments.action";

export default function AssignmentPage() {
  const assignments = useSelector((state) => state.assignments)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(assignmentsFetch())
  }, [dispatch]);

  const [isCurrentSellected, setCurrentSellected] = useState(true);
  const [isDropDownOpen, setDropDownOpen] = useState(false);

  const handleDropDownClick = () => {
    setDropDownOpen(!isDropDownOpen);
  }
  const handleDropDownSelect = (e) => {
    console.log(e.target?.value)
    // setCurrentSellected()
  }
  // onDropDownArrowClick = (optionValue) => {
  //   this.setState({ dropDownSellectedOption: optionValue });
  // };

  // onDropDownOptionClick = (option) => {
  //   this.setState({ dropDownSellectedOption: option });
  // };

  return (
    <div>
      <div className="assignment-titleContainer">Assignments</div>
      <div style={{ display: "flex" }}>
        <div className="assignment-filterBlocks" onClick={() => setCurrentSellected(true)}>
          <img
            className="assignment-radioButton"
            src={isCurrentSellected ? CheckedRadioButton : UnCheckedRadioButton}
            alt="Current Assignments"
          />
          <p className="assignment-radioButtonText">Current Assignments</p>
        </div>
        <div className="assignment-filterBlocks" onClick={() => setCurrentSellected(false)}>
          <img
            className="assignment-radioButton"
            src={!isCurrentSellected ? CheckedRadioButton : UnCheckedRadioButton}
            alt="Previous Assignments"
          />
          <p className="assignment-radioButtonText">Previous Assignments</p>
        </div>


        <DropDownComponent
          onArrowClick={handleDropDownClick}
          onOptionClick={handleDropDownSelect}
          options={["Least days to expire", "Most days to expire", "Most responses", "Least Responses"]}
          placeholder="List days of Expire"
          style={{
            marginRight: "20px",
            alignItems: "center",
            borderRadius: "5px",
          }}
        />

        <NavLink className="assignment-addButton" to="/admin/assignments/addNew">
          <img src={AddIconWhite} alt="Add New" />
          <div className="assignmentDetails-addNewText" >
            Add New
          </div>
        </NavLink>
      </div>
      <div className="assignment-listMainContainer">
        {assignments.loading ?
          <h1>Loading...</h1>
          :
          assignments.data.length > 0 ?
            assignments.data.map((option) => {
              return (
                <div key={option.id} className="assignment-listContainer">
                  <div className="assignment-detailBlock">
                    <div className="assignment-descriptionTitle">
                      <span className="assignment-assignmentId">
                        {option.name ? option.name : "N/A"}
                      </span>
                      <span className="assignment-saperator1"></span>
                      <span className="assignment-descriptionText2">
                        Expiring on :{" "}
                        {option.endDate
                          ? option.endDate.substring(0, 10)
                          : "N/A"}
                      </span>
                      {/* <span className="assignment-saperator1"></span>
                        <span className="assignment-descriptionText2">
                          Responses: 2
                        </span> */}
                    </div>
                    <div className="assignment-descriptionDetails">
                      {option.description ? option.description : "N/A"}
                    </div>
                  </div>
                  <NavLink
                    to={"/admin/assignments/" + option.id}
                    className="assignment-responseBlock"
                  >
                    <div className="assignment-responseInnerBlock">
                      <div className="assignment-responseInnerBlock2">
                        <p className="assignment-responseText">
                          View Responses
                        </p>
                        <img
                          className="assignment-rigthArrow"
                          src={ArrowRight}
                          alt={"View Responses"}
                        />
                      </div>
                    </div>
                  </NavLink>
                </div>
              );
            }) : <h1 style={{ textAlign: "center" }}>No Data found</h1>}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import SearchIcon from "../../Assets/Images/Search.svg";

import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { dataCollectionListFetch, dataCollectionListReset } from "../../actions/dataCollection.action";

import { eClinicListFetch } from "../../actions/eClinicList.action";
import CalendarComponent from "../../Components/calendar/index";

import Tabel from "../../Components/Tabel";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";



import Modal from "../../Components/Modal";
import ArrowRight from "../../Assets/Images/ArrowRight.svg";

import states from "../../utils/state.json";

import DropDownComponent from "../../Components/DropDown/index";

import { onConvertJsonToExcel } from '../../utils/JsonToExcelConverter'

const Rewards = () => {

    const [dataCollectionFromDate, setCollectionFromDate] = useState("");
    const [dataCollectionToDate, setCollectionToDate] = useState("");
    const [allState] = useState(Object.keys(states));
    const [allEClinic, setAllEClinic] = useState([]);
    const [allCity, setAllCity] = useState(states["Odisha"]);

    const [district, setDistrict] = useState();
    const [eclinicName, setEClinicName] = useState();
    const [mobileNumber, setMobileNumber] = useState();
    const [fullName, setFullName] = useState();
    const [village, setVillage] = useState();

    const [isActionModal, setActionModal] = useState(false);

    const handleSearchByName = (e) => {
        setFullName(e.target.value);
    };

    const handleSearchByVillage = (e) => {
        setVillage(e.target.value);
    };

    const handleSearchByMobileNumber = (e) => {
        setMobileNumber(e.target.value);
    };
    const dataCollectionList = useSelector((state) => state.dataCollectionList);
    const eClinicList = useSelector((state) => state.eClinicList);
    const dispatch = useDispatch();

    useEffect(() => {
        let payload = {};
        dispatch(dataCollectionListFetch(payload));
        dispatch(eClinicListFetch());

        let newAr = eClinicList.data.map((item, index, arr) => {
            return item.name;
        });
        setAllEClinic(newAr);
    }, [dispatch]);

    const onClickAtFilter = () => {
        setDistrict()
        setMobileNumber()
        setEClinicName()
        setVillage()
        setFullName()
        setActionModal(!isActionModal);
    };

    const onApplyFilter = () => {
        dispatch(dataCollectionListReset());
        let obj = {
            dataCollectionFromDate: dataCollectionFromDate,
            dataCollectionToDate: dataCollectionToDate,
            district: district,
            village: village,
            eclinicName: eclinicName,
            fullName: fullName,
            mobileNumber: mobileNumber,
        };

        let payload = Object.entries(obj).reduce((a, [k, v]) => (v == null || v === "" ? a : (a[k] = v, a)), {});
        dispatch(dataCollectionListFetch(payload));

        setActionModal(!isActionModal);
    };

    const onDropDownArrowClick = (optionValue) => {
        // this.setState({ dropDownSellectedOption: optionValue });
    };



    const onDropDownOptionStateClick = (option) => {
        setAllCity(states[option]);
        // setState(option);
    };

    const onDropDownOptionDistrictClick = (item) => {
        setDistrict(item);
    };

    const onDropDownOptionEClinicClick = (item) => {
        setEClinicName(item);
    };

    const onDownLoadFile = () => {

        if (dataCollectionList?.data?.length === 0) {
            return
        }
        onConvertJsonToExcel(dataCollectionList?.data, "DataCollection" + new Date())
    }

    return (
        <>
            <div>
                <div

                    className="addNew-calendalContainer"
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <div className="promoList-title">Data Collection List</div>

                        <div className="download" style={{ display: "flex", flexDirection: "row" }} onClick={() => onClickAtFilter()}>
                            <FontAwesomeIcon icon={faFilter} />

                            <div className="download" style={{ marginLeft: 10 }}>Filter</div>
                        </div>
                    </div>
                </div>

                <div

                    className="addNew-calendalContainer"
                    style={{ justifyContent: 'flex-end' }}
                >

                    <div className="downloadResponse">{`Total there are ${dataCollectionList?.data?.length} responses : `}</div>

                    <div className="download" onClick={() => onDownLoadFile()}><u>Download File</u> </div>
                </div>

                <Tabel style={{ margin: "30px 0" }}>
                    <thead>
                        <tr>
                            <th>eClinic Name</th>
                            <th>Name</th>
                            <th>Mobile Number</th>
                            <th>Village</th>
                            <th>State</th>
                            <th>District</th>

                            <th>Data Collection Date</th>
                            <th>View Details</th>

                            {/* <th>Action</th> */}
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "#fff" }}>
                        {dataCollectionList?.data?.loading ? (
                            <tr>
                                <td colSpan={9}>
                                    <h2>Loading...</h2>
                                </td>
                            </tr>
                        ) : dataCollectionList?.data?.length === 0 ? (
                            <tr>
                                <td colSpan={9}>
                                    <h2>No Data found</h2>
                                </td>
                            </tr>
                        ) : (
                            dataCollectionList?.data?.map((item) => (
                                <tr key={item.id}>
                                    <td>{item?.eclinicName}</td>
                                    <td>{item?.fullName}</td>
                                    <td>{item?.mobileNumber}</td>
                                    <td>{item?.village}</td>
                                    <td>{item?.state}</td>
                                    <td>{item?.district}</td>

                                    <td>{item?.dataCollectionDate?.split("T")[0]}</td>
                                    <td>


                                        <NavLink
                                            to={"/admin/dataCollectionInfo/" + item.id}
                                            className="assignment-responseBlock"
                                        >
                                            <div className="assignment-responseInnerBlock">
                                                <div className="assignment-responseInnerBlock2">
                                                    <p className="assignment-responseText">Details</p>
                                                    <img
                                                        className="assignment-rigthArrow"
                                                        src={ArrowRight}
                                                        alt={"View Responses"}
                                                    />
                                                </div>
                                            </div>
                                        </NavLink>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </Tabel>
            </div>

            <Modal show={isActionModal} >
                <div className="addNew-modal-body" style={{ width: 1000 }}>
                    <div className="addNew-calendalContainer">
                        <div className="addNew-startDateContainer">
                            <CalendarComponent
                                title="Choose Start Date"
                                onDateChange={(startDate) => {
                                    setCollectionFromDate(startDate);
                                }}
                                disableDate={{ maxDate: new Date() }}
                            />
                            {/* {errorMsg["startDate"]?.length > 0 && <div>{errorMsg["startDate"]}</div>} */}
                        </div>
                        <div className="addNew-endDateContainer">
                            <CalendarComponent
                                title="Choose End Date"
                                onDateChange={(endDate) => {
                                    setCollectionToDate(endDate);
                                }}
                                disableDate={{
                                    minDate: dataCollectionFromDate
                                        ? new Date(dataCollectionFromDate)
                                        : new Date(),
                                    maxDate: new Date()
                                }}
                            />
                        </div>
                    </div>

                    <div className="addNew-calendalContainer" style={{ marginTop: 40 }}>
                        <div className="addNew12-startDateContainer">
                            <DropDownComponent
                                onArrowClick={() => onDropDownArrowClick()}
                                onOptionClick={(item) => onDropDownOptionStateClick(item)}
                                options={allState}
                                placeholder="Odisha"
                            />
                        </div>

                        <div className="addNew-centerDateContainer">
                            <DropDownComponent
                                onArrowClick={() => onDropDownArrowClick()}
                                onOptionClick={(item) => onDropDownOptionDistrictClick(item)}
                                options={allCity}
                                placeholder="Select District"
                            />
                        </div>

                        <div className="addNew-endDateContainer">
                            <DropDownComponent
                                onArrowClick={() => onDropDownArrowClick()}
                                onOptionClick={(item) => onDropDownOptionEClinicClick(item)}
                                options={allEClinic}
                                placeholder="Select eClinic Name"
                            />
                        </div>
                    </div>

                    <div className="addNew-calendalContainer" style={{ marginTop: 40 }}>
                        <div className="rewards-searchContainer">
                            <img src={SearchIcon} className="rewards-searchIcon" alt="logo" />
                            <input
                                className="rewards-searchInput"
                                placeholder="Search by Name"
                                onChange={handleSearchByName}
                                value={fullName}
                            />
                        </div>
                        <div className="rewards-searchContainer">
                            <img src={SearchIcon} className="rewards-searchIcon" alt="logo" />
                            <input
                                className="rewards-searchInput"
                                placeholder="Search by Mobile Number"
                                onChange={handleSearchByMobileNumber}
                                value={mobileNumber}
                            />
                        </div>
                        <div className="rewards-searchContainer">
                            <img src={SearchIcon} className="rewards-searchIcon" alt="logo" />
                            <input
                                className="rewards-searchInput"
                                placeholder="Search by Village Name"
                                onChange={handleSearchByVillage}
                                value={village}
                            />
                        </div>
                    </div>

                    <div className="newBtn" style={{ marginTop: 60 }}>
                        <div className="submitted-msg" onClick={() => onClickAtFilter()}>
                            Cancel
                        </div>
                        <button
                            type="submit"
                            className="cureBayContent-sendBtn"
                            onClick={() => onApplyFilter()}
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </Modal>



        </>
    );
};

export default Rewards;

import React from 'react'
import './App.css';
import AssignmentPage from './Screens/Assignment/index';
import PanelistPage from './Screens/Panelist/index';
import AssignmentDetailsPage from './Screens/Assignment/assignmentDetails';
import Rewards from './Screens/Rewards/index';
import AddNew from './Screens/AddNew/index';
import CureBayContent from './Screens/CurebayContent';
import AddCureBayContent from './Screens/CurebayContent/AddNew';
import SMPage from './Screens/SwasthyaMitra/SMPage';

import {
  BrowserRouter,
  Routes,
  Navigate,
  Route,
} from "react-router-dom";
import CureBayLogin from './Screens/CureBayLogin';
import PageNotFound from './Screens/PageNotFound';
import Health from './Screens/Health';
// import PotentialLead from './Screens/PotentialLead';
import PanelistProfile from './Screens/Panelist/PanelistProfile';
import DashboardLayout from './Components/DashboardLayout';
import Profile from './Screens/Profile';
import Dashboard from './Screens/Dashboard';
// import PromotionList from './Screens/PromotionList';
import SurgeryReferLeads from './Screens/SurgeryReferLeads';
import DataCollectionInfo from './Screens/DataCollectionInfo';
import DataCollectionDetails from './Screens/DataCollectionDetails';
import SMAgreement from "./Screens/SwasthyaMitra/SMAgreement"

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Navigate to='/admin' />} />
        <Route path='admin' element={<CureBayLogin />} />
        <Route path="admin/health" element={<Health />} />
        <Route path='admin' element={<DashboardLayout />}  >
          <Route path='panelists'>
            <Route index element={<PanelistPage />} />
            <Route path=':id' element={<PanelistProfile />} />
          </Route>

          <Route path='assignments' >
            <Route index element={<AssignmentPage />} />
            <Route path=':id' element={<AssignmentDetailsPage />} />
            <Route path='addNew' element={<AddNew />} />
          </Route>

          <Route path='rewards' element={<Rewards />} />

          <Route path='curebayContent' >
            <Route index element={<CureBayContent />} />
            <Route path='addNew' element={<AddCureBayContent />} />
          </Route>

          {/* <Route path="potential-lead" element={<PotentialLead />} /> */}
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="profile" element={<Profile />} />
          {/* <Route path="promotionList" element={<PromotionList />} /> */}
          <Route path="surgeryReferLeads" element={<SurgeryReferLeads />} />
          <Route path="dataCollectionInfo"  >

            <Route index element={<DataCollectionInfo />} />

            <Route path=':id' element={<DataCollectionDetails />} />




          </Route>
          <Route path='smpage'>
            <Route index element={<SMPage />} />

          </Route>
          <Route path='smagreement'>
            <Route index element={<SMAgreement />} />
            {/* <Route path=':id' element={<PanelistProfile />} /> */}
          </Route>
        </Route>
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
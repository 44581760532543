import { call, put, takeEvery } from "redux-saga/effects";
import { assignmentDetailsNotReviewedTypes, assignmentDetailsReviewedTypes, assignmentDetailsTypes } from "../types";
import { assignmentDetailsSuccess, assignmentDetailsError, assignmentDetailsReviewedSuccess, assignmentDetailsReviewedError, assignmentDetailsNotReviewedSuccess, assignmentDetailsNotReviewedError } from "../actions/assignmentDetails.action";
import { getAssignmentDetails, getAssignmentDetailsResponse } from "../services/api"
function* fetchassignmentDetails({ payload }) {
    try {
        const assignmentDetails = yield call(getAssignmentDetails, payload);
        yield put(assignmentDetailsSuccess(assignmentDetails.data));
    } catch (e) {
        yield put(assignmentDetailsError(e));
    }
}

function* fetchReviewedAssignmentDetails({ payload }) {
    try {
        const assignmentDetails = yield call(getAssignmentDetailsResponse, payload);
        yield put(assignmentDetailsReviewedSuccess(assignmentDetails.data.panelists));
    } catch (e) {
        yield put(assignmentDetailsReviewedError(e));
    }
}
function* fetchNotReviewedAssignmentDetails({ payload }) {
    try {
        const assignmentDetails = yield call(getAssignmentDetailsResponse, payload);
        yield put(assignmentDetailsNotReviewedSuccess(assignmentDetails.data.panelists));
    } catch (e) {
        yield put(assignmentDetailsNotReviewedError(e));
    }
}

export default function* saga() {
    yield takeEvery(assignmentDetailsTypes.FETCH, fetchassignmentDetails);
    yield takeEvery(assignmentDetailsReviewedTypes.FETCH, fetchReviewedAssignmentDetails);
    yield takeEvery(assignmentDetailsNotReviewedTypes.FETCH, fetchNotReviewedAssignmentDetails);
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import DropDownComponent from "../../Components/DropDown/index";
import SearchIcon from "../../Assets/Images/Search.svg";
import Approved from "../../Assets/Images/Approved.svg";
import member from "../../Assets/Images/member.svg";
import Reject from "../../Assets/Images/Reject.svg";
import Tick from "../../Assets/Images/Tick.svg";
import ApproveRejectBtn from "../../Components/ApproveRejectBtn";
import Cross from "../../Assets/Images/Cross.svg";
import "../SwasthyaMitra/SMPage.css";
import ImagePlaceholder from "../../Components/ImagePlaceholder";
import Mail from "../../Assets/Images/Mail.svg";
import Phone from "../../Assets/Images/Phone.svg";
import loaction from "../../Assets/Images/loaction.svg";
import bank from "../../Assets/Images/bank.svg";
import IFSC from "../../Assets/Images/IFSC.svg";
import account from "../../Assets/Images/account.svg";
import ApproveModal from "../../Components/ApproveRejectButton/ApproveModal";
import RejectModal from "../../Components/ApproveRejectButton/RejectModal";
import TransactionModal from "../../Screens/SwasthyaMitra/transactiondetails";
import pancard from "../../Assets/Images/pancard.svg";
import nodatafound from "../../Assets/Images/nodatafound.svg";
import { urls } from "../../CommonUtils/config";
import ConfirmPop from "../SwasthyaMitra/ConfirmPop";
import "../../CommonUtils/api";

const SMPage = () => {
  const storedToken = localStorage.getItem("authToken");
  console.log(storedToken, "UPDATE TOKEN");

  console.log(storedToken, "TOKEN");
  const [list, setList] = useState([]);
  const [newList, setnewList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [approvalStatus, setApprovalStatus] = useState([]);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [selectedTransactionIndex, setSelectedTransactionIndex] =
    useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedOption, setSelectedOption] = useState("Pending");
  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState([]);
  const [submitError, setSubmitError] = useState(false);
  const [formattedDate, setFormattedDate] = useState("");
  const [monthName, setMonthName] = useState("");
  const [showConfirmPop, setShowConfirmPop] = useState(false);
  const [currentRequestId, setCurrentRequestId] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const fetchDataByOption = async (option) => {
    try {
      setLoading(true); // Show loader while fetching data

      let response;
      if (option === "Approved") {
        response = await axios.get(
          `${urls.engageBaseUrl}SMRequestPaymentApproval/list?VALIDATESTATUS=A&PERIOD=0`,
          { headers: { Authorization: `Bearer ${storedToken}` } }
        );
      } else if (option === "Pending") {
        response = await axios.get(
          `${urls.engageBaseUrl}SMRequestPaymentApproval/list?VALIDATESTATUS=P&PERIOD=0`,
          { headers: { Authorization: `Bearer ${storedToken}` } }
        );
      } else if (option === "Rejected") {
        response = await axios.get(
          `${urls.engageBaseUrl}SMRequestPaymentApproval/list?VALIDATESTATUS=R&PERIOD=0`,
          { headers: { Authorization: `Bearer ${storedToken}` } }
        );
      } else if (option === "Failed Transaction") {
        response = await axios.get(
          `${urls.engageBaseUrl}SMRequestPaymentApproval/list?VALIDATESTATUS=F&PERIOD=0`,
          { headers: { Authorization: `Bearer ${storedToken}` } }
        );
      }
      const fetchedData = response.data;
      console.log("Fetched Data:", fetchedData); // Debugging
      setList(fetchedData);
      setOriginalList(fetchedData);

      setApprovalStatus(new Array(response.data.length).fill(false));
      setLoading(false);
    } catch (err) {
      console.log("error", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataByOption(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const monthIndex = currentDate.getMonth();
    const month = (monthIndex + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const monthName = getMonthName(monthIndex);
    const formatted = `${year}${month}`;

    setFormattedDate(formatted);
    setMonthName(monthName);
    // console.log(formattedDate, "Yash")
  }, []);

  console.log("Redemmed Data", list);
  useEffect(() => {
    console.log(formattedDate, "Yash");
  }, [formattedDate]);

  const getMonthName = (monthIndex) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[monthIndex];
  };

  const onApproveButtonClick = (index) => {
    // Show the modal when "Approve" button is clicked
    setSelectedIndex(index);
    setShowApproveModal(true);
  };

  const handleSubmitError = () => {
    setSubmitError(true);
  };

  const onRejectButtonClick = (index) => {
    // Show the Reject modal when "Reject" button is clicked
    setSelectedIndex(index);
    setShowRejectModal(true);
  };
  const onTransactionButtonClick = (index) => {
    setSelectedTransactionIndex(index); // Save the index of the clicked item
    setShowTransactionModal(true); // Show the TransactionModal
  };

  const handleApproveConfirmation = (approved) => {
    // Handle the approval confirmation here
    if (approved) {
      // Perform the approval action
      // ... Add your logic here ...
      console.log("Approved!");
    }

    // Close the modal regardless of the approval action
    setShowApproveModal(false);
  };

  const handleRejectConfirmation = (rejected) => {
    // Handle the rejection confirmation here
    if (rejected) {
      // Perform the rejection action
      // ... Add your logic here ...
      console.log("Rejected!");
    }

    // Close the modal regardless of the rejection action
    setShowRejectModal(false);
  };

  const onDropDownArrowClick = (optionValue) => {
    // Implement this function if needed
  };

  const onDropDownOptionClick = (options) => {
    // Implement this function if needed
    setSelectedOption(options);
    console.log(selectedOption, "Option Selected");
  };

  const onResponseClick = (data, status) => {
    console.log(data);
    let { id } = data;
    // Assuming this.props.updatePanelistStatue exists and is a function
    // this.props.updatePanelistStatue({ id, status });
  };
  const handleSearchQuery = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    console.log("Search Query:", query); // Debugging

    if (query === "") {
      // Reset to original list when query is empty
      setList(originalList);
      console.log("Reset to Original List:", originalList); // Debugging
    } else {
      // Apply filter when there is a query
      const filteredList = originalList.filter(
        (item) =>
          item.smname && item.smname.toLowerCase().includes(query.toLowerCase())
      );
      console.log("Filtered List:", filteredList); // Debugging
      setList(filteredList);
    }
  };

  const handleShowConfirmPop = (index, requestId) => {
    setCurrentIndex(index);
    setCurrentRequestId(requestId);
    setShowConfirmPop(true);
  };

  const handleCloseConfirmPop = () => {
    setShowConfirmPop(false);
    fetchDataByOption("Pending");
  };

  return (
    <div>
      <div className="titleContainer">Swasta Mitra Payments</div>
      <div className="filterContainer">
        <div className="searchContainer">
          <img src={SearchIcon} className="searchIcon" alt="logo" />
          <input
            className="searchInput"
            placeholder="Search by Name"
            value={searchQuery}
            onChange={handleSearchQuery}
          />
        </div>
        <div className="panelist-dropdown">
          <DropDownComponent
            onArrowClick={onDropDownArrowClick}
            onOptionClick={onDropDownOptionClick}
            options={["Pending", "Approved", "Rejected"]}
            placeholder="Sort by"
            defaultOption={selectedOption}
          />
        </div>
      </div>

      {loading ? (
        <div className="loader"></div>
      ) : list && list.length > 0 ? (
        list?.map((data, i) => (
          <div key={i} className="swasthyamitracard">
            <div className="block">
              <div className="Details">
                <ImagePlaceholder name={data?.smname} />

                <div className="detailsContainer">
                  <div className="name">
                    <p className="cardTitle">{data?.smname}</p>
                    <p className="request">Request Id: {data.id}</p>
                  </div>

                  <div className="Detailspro">
                    <div className="Details">
                      <img src={member} className="Icon" alt="logo" />
                      <span className="botto-text">{data?.swasthuserid}</span>
                      <img src={Phone} className="Icon" alt="logo" />
                      <span className="botto-text">{data?.mobile}</span>
                      <img src={Mail} className="Icon" alt="logo" />
                      <span className="botto-text">{data?.email}</span>
                      <img src={loaction} className="Icon" alt="logo" />
                      <span className="botto-text">{data?.banklocation}</span>
                      <img src={bank} className="Icon" alt="logo" />
                      <span className="botto-text">{data?.bankname}</span>

                      {/* <img src={IFSC} className="Icon" alt="logo" />
                      <span className="botto-text">{data?.bankifsc}</span> */}
                    </div>
                    <img src={IFSC} className="Icon" alt="logo" />
                    <span className="botto-text">{data?.bankifsc}</span>
                    <img src={account} className="Icon" alt="logo" />
                    <span className="botto-text">
                      {data?.bankaccountnumber}
                    </span>
                    <img src={pancard} className="Icon" alt="logo" />
                    <span className="botto-text">
                      Pan Card-{data?.pannumber}
                    </span>
                    {selectedOption === "Failed Transaction" && (
                      <>
                        <img src={IFSC} className="Icon" alt="logo" />
                        <span
                          className="botto-text "
                          style={{ fontWeight: "bold" }}
                        >
                          Payout ID : {data?.txnid}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="withdrawdetails">
                <div className="box2">
                  <div className="Detailsafter">
                    <p className="cardtwoTitle">Withdraw Details:</p>
                    <p className="request">
                      Request Date: {data?.requestdatetime}
                    </p>
                  </div>
                  <div className="Details">
                    <div className="detailsnew">
                      <p className="heading">Requested Amount</p>
                      <p className="subheading">{data?.amount}</p>
                    </div>
                    <div className="detailsnew">
                      <p className="heading">TDS Amount:</p>
                      <p className="subheading">{data.tdsamount}</p>
                    </div>
                    <div className="detailsnew">
                      <p className="heading">Credited Amount:</p>
                      <p className="subheading">{data.creditedamount}</p>
                    </div>

                    {selectedOption === "Approved" ||
                    selectedOption === "Rejected" ||
                    selectedOption === "Failed Transaction" ? (
                      <>
                        <div className="detailsnew">
                          <p className="heading">
                            {selectedOption === "Approved" ||
                            selectedOption === "Failed Transaction"
                              ? "Validated By:"
                              : "Rejected By:"}
                          </p>
                          <p className="subheading">{data?.validateby}</p>
                        </div>
                        <div className="detailsnew">
                          <p className="heading">Validated Date:</p>
                          <p className="subheading">{data?.validatedatetime}</p>
                        </div>
                        <div className="detailsnew">
                          <p className="heading">Valid Status:</p>
                          <p className="subheading">{data.validatestatus}</p>
                        </div>
                        <div className="detailsnew">
                          <p className="heading">Validate Remarks:</p>
                          <p className="subheading">{data.validateremarks}</p>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="button-align">
                <button
                  onClick={() => {
                    onTransactionButtonClick(i);
                  }}
                  className="buttonview"
                >
                  View Details
                </button>
                {selectedOption === "Pending" && (
                  <>
                    <button
                      className="buttonview"
                      style={{ marginLeft: "10px" }}
                      onClick={() => handleShowConfirmPop(i, data.id)}
                    >
                      Invalid PAN ?
                    </button>
                    <ApproveRejectBtn
                      onClick={() => {
                        onApproveButtonClick(i); // Only pass the index 'i' as an argument
                      }}
                      variant={"approve"}
                      icon={Tick}
                      style={{
                        height: "fit-content",
                        marginLeft: "auto",
                      }}
                    >
                      Approve
                    </ApproveRejectBtn>

                    <ApproveRejectBtn
                      onClick={() => {
                        onRejectButtonClick(i);
                      }}
                      variant={"reject"}
                      icon={Cross}
                      style={{
                        height: "fit-content",
                        marginLeft: 15,
                        marginRight: 20,
                        width: "120px",
                      }}
                    >
                      Reject
                    </ApproveRejectBtn>
                  </>
                )}

                {/* Show the modal based on the selected index */}
                {showTransactionModal && selectedTransactionIndex === i && (
                  <TransactionModal
                    onClose={() => setShowTransactionModal(false)} // Close the modal
                    requestedAmount={data?.amount}
                    swasthyaid={data?.swasthuserid}
                    swasthyaname={data?.smname}
                    formatedData={formattedDate}
                  />
                )}

                {showApproveModal && selectedIndex === i && (
                  <ApproveModal
                    onClose={handleApproveConfirmation}
                    requestid={data.id}
                    onSubmitError={handleSubmitError}
                  />
                )}

                {showRejectModal && selectedIndex === i && (
                  <RejectModal
                    onClose={handleRejectConfirmation}
                    requestid={data.id}
                  />
                )}
                {showConfirmPop && currentIndex === i && (
                  <ConfirmPop
                    onClose={() => handleCloseConfirmPop()}
                    requestId={data.id}
                  />
                )}

                {submitError && (
                  <div style={{ color: "red", textAlign: "center" }}>
                    Submit failed! Please try again later.
                  </div>
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <img src={nodatafound} className="nodatafound" alt="logo" />
      )}
    </div>
  );
};

export default SMPage;

import { call, put, takeEvery } from "redux-saga/effects";
import { activeRewardsTypes, inactiveRewardsTypes } from "../types";
import { activeRewardsSuccess, activeRewardsError, inactiveRewardsSuccess, inactiveRewardsError } from "../actions/rewards.action";
import { getActiveRewards, getInactiveRewards } from "../services/api"

function* fetchActiveRewards() {
    try {
        const activeRewards = yield call(getActiveRewards);
        yield put(activeRewardsSuccess(activeRewards.data));
    } catch (e) {
        yield put(activeRewardsError(e));
    }
}
function* fetchInactiveRewards() {
    try {
        const inactiveRewards = yield call(getInactiveRewards);
        yield put(inactiveRewardsSuccess(inactiveRewards.data));
    } catch (e) {
        yield put(inactiveRewardsError(e));
    }
}

export default function* saga() {
    yield takeEvery(activeRewardsTypes.FETCH, fetchActiveRewards);
    yield takeEvery(inactiveRewardsTypes.FETCH, fetchInactiveRewards);
}

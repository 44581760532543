import React from 'react'
import "./index.css"
const Pagination = () => {

    return (
        <div style={{ display: "flex", justifyContent: "center", padding: 30, margin: "auto" }}>
            <div className="paginationPrev pagination-disable"><span className='pagination-navIcon'>&lt;</span> Prev</div>
            <div className="paginationItem active">1</div>
            <div className="paginationItem">2</div>
            <div className="paginationItem">3</div>
            <div className="paginationBreak">...</div>
            <div className="paginationItem">20</div>
            <div className="paginationNext">Next <span className='pagination-navIcon'>&gt;</span> </div>
        </div>
    )
}

export default Pagination
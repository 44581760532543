import {
    useCallback, useEffect, useRef,
    // useState
} from "react";
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom";
import {
    leadsByPanelistIDFetch, moreLeadsByPanelistIDFetch,
    // moreLeadsByPanelistIDUpdatePage
} from "../../../actions/panelist.action";
import Tabel from "../../../Components/Tabel"
//     {
//         "id": "1",
//         "name": "Anil Nigam",
//         "city": "Bhuvneshwar",
//         "emailId": "anil@gmail.com",
//         "phoneNo": "8264233410",
//         "status": "Patient"
//     }
const LeadsByPanelistContainer = () => {
    const { id } = useParams();
    const leadsByPanelistID = useSelector((store) => store.panelistData.leadsByPanelistID)
    const dispatch = useDispatch();
    // const [pageNumber, setPageNumber] = useState(1)
    const observer = useRef(null);
    const lastEleRef = useCallback((node) => {
        if (leadsByPanelistID?.loading || leadsByPanelistID?.moreLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                if (leadsByPanelistID.hasMoreData) {
                    console.log("visible")
                    // setPageNumber((prevNumber) => prevNumber + 1)
                    // dispatch(moreLeadsByPanelistIDUpdatePage())
                    dispatch(moreLeadsByPanelistIDFetch({ id, pageNo: leadsByPanelistID.pageNumber, pageSize: 10 }))
                } else {
                    console.log("data end")
                }
            }
        })
        if (node) observer.current.observe(node);
    }, [leadsByPanelistID?.loading, leadsByPanelistID.hasMoreData, leadsByPanelistID?.moreLoading, leadsByPanelistID.pageNumber, dispatch, id])


    // useEffect(() => {
    //     // console.log("hit api", pageNumber)
    //     // intially firstfetch is false
    //     if (leadsByPanelistID.firstFetch) {
    //         if (leadsByPanelistID.pageNumber < 3) {
    //             dispatch(moreLeadsByPanelistIDFetch({ id, pageNo: leadsByPanelistID.pageNumber, pageSize: 10 }))
    //         }
    //     } else {
    //         dispatch(leadsByPanelistIDFetch({ id, pageNo: leadsByPanelistID.pageNumber, pageSize: 10 }))
    //     }
    // }, [leadsByPanelistID.pageNumber, dispatch, id, leadsByPanelistID.firstFetch])

    useEffect(() => {
        if (!leadsByPanelistID.firstFetch) {
            dispatch(leadsByPanelistIDFetch({ id, pageNo: leadsByPanelistID.pageNumber, pageSize: 10 }))
        }
    }, [leadsByPanelistID.firstFetch, dispatch, id, leadsByPanelistID.pageNumber])


    return <Tabel style={{ margin: "30px auto", width: "90%", borderRadius: 5 }}>
        <thead>
            <tr>
                <th>Name</th>
                <th>City</th>
                <th>Email Id</th>
                <th>Phone No.</th>
                <th>Status</th>
            </tr>
        </thead>
        <tbody>
            {
                leadsByPanelistID?.loading ?
                    <tr>
                        <td colSpan={5}><h2>Leads Loading...</h2></td>
                    </tr>
                    :
                    leadsByPanelistID?.data.length > 0 ?
                        <>
                            {leadsByPanelistID.data.map((lead, index, leads) => {
                                if (leads.length === index + 1) {
                                    return <tr key={lead.id} ref={lastEleRef}>
                                        <td>{lead.firstName || "N/A"}</td>
                                        <td>{lead.district || "N/A"}</td>
                                        <td>{lead.email || "N/A"}</td>
                                        <td>{lead.phoneNumber || "N/A"}</td>
                                        <td>{lead.status || "N/A"}</td>
                                    </tr>
                                } else {
                                    return <tr key={lead.id}>
                                        <td>{lead.firstName || "N/A"}</td>
                                        <td>{lead.district || "N/A"}</td>
                                        <td>{lead.email || "N/A"}</td>
                                        <td>{lead.phoneNumber || "N/A"}</td>
                                        <td>{lead.status || "N/A"}</td>
                                    </tr>
                                }
                            })}
                            {leadsByPanelistID?.moreLoading &&
                                <tr>
                                    <td colSpan={5}><h2>More Leads Loading...</h2></td>
                                </tr>
                            }
                        </>
                        :
                        <tr>
                            <td colSpan={5}><h2>No Data Found</h2></td>
                        </tr>}
        </tbody>
    </Tabel>
}
export default LeadsByPanelistContainer;
import { dataCollectionDetailsTypes } from "../types";

export function dataCollectionDetailsFetch(val) {
    return {
        type: dataCollectionDetailsTypes.FETCH,
        payload: val,
    }
}
export function dataCollectionDetailsSuccess(val) {
    return {
        type: dataCollectionDetailsTypes.SUCCESS,
        payload: val
    }
}
export function dataCollectionDetailsError(val) {
    return {
        type: dataCollectionDetailsTypes.ERROR,
        payload: val
    }
}
export function dataCollectionDetailsReset() {
    return {
        type: dataCollectionDetailsTypes.RESET
    }
}
import React, { useRef } from 'react'
import { Outlet } from 'react-router-dom'
import AppHeader from '../Header'
import AppSidebar from '../SideBar'

const DashboardLayout = () => {
    const appContentRef = useRef(null);
    return (
        <>
            <AppHeader />
            <div style={{ display: 'flex' }}>
                <div style={{
                    flex: 2, height: '90vh', zIndex: 1,
                    maxWidth: 250
                }}>
                    <AppSidebar />
                </div>
                <div ref={appContentRef} id="app-content" style={{ flex: 7, background: "#f4faff", padding: "30px 40px", height: "90vh", overflow: 'auto' }}>
                    <Outlet context={{ appContentRef }} />
                </div>
            </div>
        </>
    )
}

export default DashboardLayout
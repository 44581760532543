import { activeRewardsTypes, inactiveRewardsTypes } from "../types";
const initialState = {
    data: [],
    activeRewards: [],
    inactiveRewards: [],
    loading: false,
    error: ""
}
const reducer = (state = initialState, action) => {

    const { type, payload } = action;
    switch (type) {
        case activeRewardsTypes.FETCH:
        case inactiveRewardsTypes.FETCH:
            return { ...state, loading: true }
        case activeRewardsTypes.SUCCESS:
            return { ...state, activeRewards: payload, loading: false, error: "" }
        case inactiveRewardsTypes.SUCCESS:
            return { ...state, inactiveRewards: payload, loading: false, error: "" }
        case activeRewardsTypes.ERROR:
        case inactiveRewardsTypes.ERROR:
            return { ...state, loading: false, error: payload }
        case activeRewardsTypes.RESET:
        case inactiveRewardsTypes.RESET:
            return initialState;
        default:
            return state;
    }
}

export default reducer;
import { call, put, takeEvery } from "redux-saga/effects";
import { updatePanelistStatusTypes } from "../types";
import { updatePanelistStatueSuccess, updatePanelistStatueError } from "../actions/panelist.action";
import { updatePanelistStatus } from "../services/api"

function* approveRejectPanelistStatus({ payload }) {
    try {
        const panelist = yield call(updatePanelistStatus, payload);
        yield put(updatePanelistStatueSuccess(panelist.data));
    } catch (e) {
        yield put(updatePanelistStatueError(e));
    }
}

export default function* saga() {
    yield takeEvery(updatePanelistStatusTypes.UPDATE, approveRejectPanelistStatus);
}

import { call, put, takeEvery } from "redux-saga/effects";
import { dataCollectionListError, dataCollectionListSuccess } from "../actions/dataCollection.action";

import { getDataCollectionList } from "../services/api"
import { dataCollectionListTypes } from "../types";

function* fetchDataCollectionList({ payload }) {
    try {
        const dataCollectionList = yield call(getDataCollectionList, payload);
        yield put(dataCollectionListSuccess(dataCollectionList));
    } catch (e) {
        yield put(dataCollectionListError(e));
    }
}

export default function* saga() {
    yield takeEvery(dataCollectionListTypes.FETCH, fetchDataCollectionList);
}

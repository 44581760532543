import { call, put, takeEvery } from "redux-saga/effects";
import { surgeryReferLeadsError, surgeryReferLeadsSuccess, surgeryReferLeadsUpdateStatusError, surgeryReferLeadsUpdateStatusSuccess } from "../actions/surgeryReferLeads.action";
import { getSurgeryReferLeads, postSurgeryReferLeads } from "../services/surgeryReferLeads.api";
import { surgeryReferLeadsTypes, surgeryReferLeadsUpdateTypes } from "../types";

function* fetchSurgeryReferLeads({ payload }) {
    try {
        const { data } = yield call(getSurgeryReferLeads, payload);
        yield put(surgeryReferLeadsSuccess(data.data));
    } catch (e) {
        yield put(surgeryReferLeadsError(e));
    }
}
function* updateSurgeryReferLeadsStatus({ payload }) {
    try {
        yield call(postSurgeryReferLeads, payload);
        yield put(surgeryReferLeadsUpdateStatusSuccess());
    } catch (e) {
        console.log(e, "surgeryReferLeadsError while status updating");
        yield put(surgeryReferLeadsUpdateStatusError(e));
    }
}

export default function* saga() {
    yield takeEvery(surgeryReferLeadsTypes.FETCH, fetchSurgeryReferLeads);
    yield takeEvery(surgeryReferLeadsUpdateTypes.FETCH, updateSurgeryReferLeadsStatus);
}

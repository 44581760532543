import { useSelector } from "react-redux";
import Tabel from "../../../Components/Tabel";
//     {
//         "id": "1",
//         "name": "panelist referred",
//         "city": "Bhuvneshwar",
//         "emailId": "anil@gmail.com",
//         "phoneNo": "8264233410",
//         "status": "Patient"
//     }
const ReferredPanelistContainer = () => {
    const referredPanelistsByID = useSelector((store) => store.panelistData.referredPanelistsByID)
    return <Tabel style={{ margin: "30px auto", width: "90%", borderRadius: 5 }}>
        <thead>
            <tr>
                <th>Name</th>
                <th>Email Id</th>
                <th>Phone No.</th>
                <th>Status</th>
            </tr>
        </thead>
        <tbody>
            {referredPanelistsByID?.loading ?
                <tr>
                    <td colSpan={5}><h2>Referred Panelists Loading...</h2></td>
                </tr>
                :
                referredPanelistsByID.data?.length > 0 ?
                    referredPanelistsByID.data.map((panelist) => (
                        <tr key={panelist.id}>
                            <td>{panelist.panelistName || "N/A"}</td>
                            <td>{panelist.email || "N/A"}</td>
                            <td>{panelist.panelistPhoneNumber || "N/A"}</td>
                            <td>{panelist.status || "N/A"}</td>
                        </tr>
                    ))
                    :
                    <tr>
                        <td colSpan={5}><h2>No Data Found</h2></td>
                    </tr>}
        </tbody>
    </Tabel>
}
export default ReferredPanelistContainer;
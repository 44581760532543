import React from 'react'
import "./index.css"
const Tabel = ({ children, ...props }) => {
    return (
        <table className='tabel-dataContainer' {...props}>
            {children}
        </table>
    )
}

export default Tabel
import {
    recentPanelistTypes, activePanelistTypes, updatePanelistStatusTypes, panelistDetailsTypes,
    referredPanelistsByID,
    leadsByPanelistID,
    moreLeadsByPanelistID,
    activeInActivePanelistStatusTypes
} from "../types";
const { FETCH, SUCCESS, ERROR, RESET } = recentPanelistTypes;

// RECENT PANELISTS
export function recentpanelistFetch() {
    return {
        type: FETCH
    }
}
export function panelistSuccess(val) {
    return {
        type: SUCCESS,
        payload: val
    }
}
export function panelistError(val) {
    return {
        type: ERROR,
        payload: val
    }
}


export function activeInActivePanelistError(val) {
    return {
        type: ERROR,
        payload: val
    }
}
export function panelistReset() {
    return {
        type: RESET
    }
}

// ACTIVE PANELISTS
export function activepanelistFetch() {
    return {
        type: activePanelistTypes.FETCH
    }
}

export function activePanelistSuccess(val) {
    return {
        type: activePanelistTypes.SUCCESS,
        payload: val
    }
}




export function updatePanelistStatue(payload) {
    return {
        type: updatePanelistStatusTypes.UPDATE,
        payload
    }
}

export function updatePanelistStatueSuccess(val) {
    return {
        type: updatePanelistStatusTypes.SUCCESS,
        payload: val
    }
}

export function updatePanelistStatueReset() {
    return {
        type: updatePanelistStatusTypes.RESET,
        payload: null
    }
}

export function updatePanelistStatueError(val) {
    return {
        type: updatePanelistStatusTypes.SUCCESS,
        payload: val
    }
}



//Active Or In Active panalist
export function panelistActiveInActiveFetch(val) {
    return {
        type: activeInActivePanelistStatusTypes.FETCH,
        payload: val
    }
}
export function panelistActiveInActiveSuccess(val) {
    return {
        type: activeInActivePanelistStatusTypes.SUCCESS,
        payload: val
    }
}
export function panelistActiveInActiveError(val) {
    return {
        type: activeInActivePanelistStatusTypes.ERROR,
        payload: val
    }
}
export function panelistActiveInActiveReset() {
    return {
        type: activeInActivePanelistStatusTypes.RESET
    }
}

export function panelistActiveInActiveRes(val) {

    return {
        type: activeInActivePanelistStatusTypes.RESPONSE,
        payload: val
    }
}




// PANELIST DETAILS
export function panelistDetailsFetch(val) {
    return {
        type: panelistDetailsTypes.FETCH,
        payload: val
    }
}
export function panelistDetailsSuccess(val) {
    return {
        type: panelistDetailsTypes.SUCCESS,
        payload: val
    }
}
export function panelistDetailsError(val) {
    return {
        type: panelistDetailsTypes.ERROR,
        payload: val
    }
}
export function panelistDetailsReset() {
    return {
        type: panelistDetailsTypes.RESET
    }
}

// REFERRED PANELISTS BY ID ------------------
export function referredPanelistsByIDFetch(val) {
    return {
        type: referredPanelistsByID.FETCH,
        payload: val
    }
}
export function referredPanelistsByIDSuccess(val) {
    return {
        type: referredPanelistsByID.SUCCESS,
        payload: val
    }
}
export function referredPanelistsByIDError(val) {
    return {
        type: referredPanelistsByID.ERROR,
        payload: val
    }
}
export function referredPanelistsByIDReset() {
    return {
        type: referredPanelistsByID.RESET
    }
}


// LEADS BY PANELISTS ID  ------------------
export function leadsByPanelistIDFetch(val) {
    return {
        type: leadsByPanelistID.FETCH,
        payload: val
    }
}
export function leadsByPanelistIDSuccess(val) {
    return {
        type: leadsByPanelistID.SUCCESS,
        payload: val
    }
}
export function leadsByPanelistIDError(val) {
    return {
        type: leadsByPanelistID.ERROR,
        payload: val
    }
}

export function leadsByPanelistIDReset() {
    return {
        type: leadsByPanelistID.RESET
    }
}

// MORE LEADS_BY_PANELIST_ID
export function moreLeadsByPanelistIDFetch(val) {
    return {
        type: moreLeadsByPanelistID.FETCH,
        payload: val
    }
}
export function moreLeadsByPanelistIDSuccess(val) {
    return {
        type: moreLeadsByPanelistID.SUCCESS,
        payload: val
    }
}
export function moreLeadsByPanelistIDError(val) {
    return {
        type: moreLeadsByPanelistID.ERROR,
        payload: val
    }
}

export function moreLeadsByPanelistIDReset() {
    return {
        type: moreLeadsByPanelistID.RESET
    }
}
// UPDATE_PAGE_NUMBER
// export function moreLeadsByPanelistIDUpdatePage(val) {
//     return {
//         type: moreLeadsByPanelistID.UPDATE_PAGE_NUMBER,
//         payload: val
//     }
// }

//UPDATE_HAS_MORE_PANELIST_MORE_LEADS
export function moreLeadsByPanelistIDUpdateHasMore(val) {
    return {
        type: moreLeadsByPanelistID.UPDATE_HAS_MORE,
        payload: val
    }
}
import ImagePlaceholder from "../../../Components/ImagePlaceholder";
import Mail from "../../../Assets/Images/Mail.svg";
import Phone from "../../../Assets/Images/Phone.svg";
import ApproveRejectBtn from "../../../Components/ApproveRejectBtn";
import Cross from "../../../Assets/Images/Cross.svg";

import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect } from "react";
import Modal from "../../../Components/Modal";




import {
    panelistActiveInActiveFetch,
    panelistActiveInActiveReset
} from '../../../actions/panelist.action';


const CountLabel = ({ count, placeholder }) => {
    return (
        <div>
            <div className="footerTextCount">{count === null ? "N/A" : count}</div>
            {placeholder && placeholder}
        </div>
    )
}


const PanelistDetailsCard = ({ panelistDetails }) => {

    const [isModalOpen, setModalOpen] = useState(false);
    const handleCloseModal = () => {
        setModalOpen(false)

        dispatch(panelistActiveInActiveReset())
    }

    const activeInActive = useSelector((store) => store.activeInActive)
    const dispatch = useDispatch();
    const handleStatus = (data) => {
        const params = {
            id: data?.id,
            status: "inactive"

        };

        dispatch(panelistActiveInActiveFetch(params));
    }
    const { data } = activeInActive

    useEffect(() => {
        if (data === "success") {
            setModalOpen(true)
        }

        console.log('dkjd', activeInActive)

    }, [activeInActive])


    return (<>
        <div className="panelistProfile-CardContainer">

            <div className="panelistProfile-CardContainer">
                <ImagePlaceholder name={panelistDetails?.name} />
                {/* <div className='profileImgContainer'><img src={ProfileIcon} className="ProfileIcon" alt="logo" /></div> */}
                <div className="profileDetailsContainer">
                    <p className="profileCardTitle">{panelistDetails?.name ? panelistDetails.name : "N/A"}</p>
                    <p className="profileReferData">
                        {panelistDetails?.createdDate
                            ? "Joining Date: " + panelistDetails.createdDate.substring(0, 10)
                            : "N/A"}
                    </p>
                    <div style={{ display: "flex", marginBottom: 5 }}>
                        <img src={Phone} style={{ width: 20, height: 20 }} alt="logo" />
                        <span className="profilePhoneNumber" >
                            {/* {panelistDetails.mobilePhone ? panelistDetails.mobilePhone : "N/A"} */}
                            {panelistDetails.number ? panelistDetails.number : "N/A"}
                        </span>
                    </div>
                    <div style={{ display: "flex", marginBottom: 5 }}>
                        <img src={Mail} style={{ width: 20, height: 20 }} alt="logo" />
                        <span className="profileEmailID">{panelistDetails.email ? panelistDetails.email : "N/A"}</span>
                    </div>
                </div>
            </div>

            <div style={{ display: "flex", padding: 20, flexWrap: "wrap" }}>

                {/* <ApproveRejectBtn
                    onClick={() => handleStatus("Approved")}
                    variant={"approve"} icon={Tick} style={{ height: "fit-content", marginLeft: "auto" }} >
                    Active
                </ApproveRejectBtn> */}

                {

                    !activeInActive?.loading ?
                        <ApproveRejectBtn
                            onClick={() => handleStatus(panelistDetails)}
                            variant={"reject"} icon={Cross} style={{ height: "fit-content", marginLeft: 7 }} >
                            In Active
                        </ApproveRejectBtn>

                        : <div> loading...</div>

                }



            </div>
        </div>
        <div className="profileCardFooterContainer">
            {/* <CountLabel placeholder={"Assignments "} count={data.totalAssignmentCount} tooltip={`Total Assignment Completed`} /> */}
            <CountLabel count={panelistDetails.totalAssignmentCount} placeholder={"Assignments Completed"} />
            <CountLabel count={panelistDetails.totalLeadCount} placeholder={"Total Leads Added"} />
            <CountLabel count={panelistDetails.totalRewardPoints} placeholder={"Reward Points Collected till date"} />
            <CountLabel count={panelistDetails.totalTransactionAmount} placeholder={"Total Payments "} />
        </div>


        <Modal show={isModalOpen} onHide={handleCloseModal}>
            <div className="addNew-modal-body">
                <h1>Panelist made inactive  successfully</h1>
                <div className="addNew-modal-link" to="/admin/assignments" onClick={handleCloseModal}>
                    <ApproveRejectBtn variant={"approve"}>OKAY</ApproveRejectBtn>
                </div>
            </div>
        </Modal>
    </>)
}
export default PanelistDetailsCard;
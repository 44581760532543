import { activeRewardsTypes, inactiveRewardsTypes } from "../types";
// const { FETCH, SUCCESS, ERROR, RESET } = activeRewardsTypes;

export function activeRewardsFetch() {
    return {
        type: activeRewardsTypes.FETCH
    }
}
export function activeRewardsSuccess(val) {
    return {
        type: activeRewardsTypes.SUCCESS,
        payload: val
    }
}
export function activeRewardsError(val) {
    return {
        type: activeRewardsTypes.ERROR,
        payload: val
    }
}
export function activeRewardsReset() {
    return {
        type: activeRewardsTypes.RESET
    }
}

//inactive

export function inactiveRewardsFetch() {
    return {
        type: inactiveRewardsTypes.FETCH
    }
}
export function inactiveRewardsSuccess(val) {
    return {
        type: inactiveRewardsTypes.SUCCESS,
        payload: val
    }
}
export function inactiveRewardsError(val) {
    return {
        type: inactiveRewardsTypes.ERROR,
        payload: val
    }
}
export function inactiveRewardsReset() {
    return {
        type: inactiveRewardsTypes.RESET
    }
}
import { surgeryReferLeadsTypes, surgeryReferLeadsUpdateTypes } from "../types";

export function surgeryReferLeadsFetch() {
    return {
        type: surgeryReferLeadsTypes.FETCH
    }
}
export function surgeryReferLeadsSuccess(val) {
    return {
        type: surgeryReferLeadsTypes.SUCCESS,
        payload: val
    }
}
export function surgeryReferLeadsError(val) {
    return {
        type: surgeryReferLeadsTypes.ERROR,
        payload: val
    }
}
export function surgeryReferLeadsReset() {
    return {
        type: surgeryReferLeadsTypes.RESET
    }
}

export function surgeryReferLeadsUpdateStatusFetch(val) {
    return {
        type: surgeryReferLeadsUpdateTypes.FETCH,
        payload: val
    }
}
export function surgeryReferLeadsUpdateStatusSuccess() {
    return {
        type: surgeryReferLeadsUpdateTypes.SUCCESS,
    }
}
export function surgeryReferLeadsUpdateStatusError(val) {
    return {
        type: surgeryReferLeadsUpdateTypes.ERROR,
        payload: val
    }
}

export function surgeryReferLeadsUpdateStatusReset(val) {
    return {
        type: surgeryReferLeadsUpdateTypes.RESET,
    }
}


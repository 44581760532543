import { call, put, takeEvery } from "redux-saga/effects";
import { dataCollectionDetailsError, dataCollectionDetailsSuccess } from "../actions/dataCollectionDetails.action";

import { getDataCollectionDetails } from "../services/api"
import { dataCollectionDetailsTypes } from "../types";

function* fetchDataCollectionDetails({ payload }) {
    try {
        const dataCollectionDetails = yield call(getDataCollectionDetails, payload);
        yield put(dataCollectionDetailsSuccess(dataCollectionDetails));
    } catch (e) {
        yield put(dataCollectionDetailsError(e));
    }
}

export default function* saga() {
    yield takeEvery(dataCollectionDetailsTypes.FETCH, fetchDataCollectionDetails);
}
import { surgeryReferLeadsUpdateTypes } from "../../types";
const initialState = {
    data: null,
    modalOpen: false,
    loading: false,
    error: "",
}
const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    const { FETCH, SUCCESS, ERROR, RESET } = surgeryReferLeadsUpdateTypes;
    switch (type) {
        case FETCH:
            return { ...state, loading: true, modalOpen: true, data: payload }
        case SUCCESS:
            return { ...state, loading: false, error: "" }
        case ERROR:
            return { ...state, loading: false, error: payload }
        case RESET:
            return { ...state, modalOpen: false, data: null, loading: false };
        default:
            return state;
    }
}

export default reducer;
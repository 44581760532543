import { call, put, takeEvery } from "redux-saga/effects";
import { addAssignmentTypes, assignmentsTypes } from "../types";
import { assignmentsSuccess, assignmentsError, addAssignmentsError, addAssignmentRes } from "../actions/assignments.action";
import { addAssignment, getAllAssignments } from "../services/api"

function* fetchAssignments() {
    try {
        const assignments = yield call(getAllAssignments);
        yield put(assignmentsSuccess(assignments.data));
    } catch (e) {
        yield put(assignmentsError(e));
    }
}

function* addAssignments({ payload }) {
    try {
        const addAssignmentsResult = yield call(addAssignment, payload);
        if (addAssignmentsResult.status === 200) {
            // yield put(addAssignmentsSuccess(addAssignmentsResult.data));
            // yield put(addAssignmentsReset())
            yield put(addAssignmentRes("success"))
        } else {
            yield put(addAssignmentsError(addAssignmentsResult.message));
        }
    } catch (e) {
        yield put(addAssignmentsError(e));
    }
}

export default function* saga() {
    yield takeEvery(assignmentsTypes.FETCH, fetchAssignments);
    yield takeEvery(addAssignmentTypes.FETCH, addAssignments);
}

import React, {
    useEffect,
    // useEffect,
    useState
} from 'react'
import { Line } from 'react-chartjs-2'
import { Chart, LineController, LineElement, PointElement, LinearScale, Title, Filler, Tooltip } from 'chart.js';
import { nFormatter } from '../../utils';
import "chartjs-adapter-date-fns"
// import { dashboardPanelistFetch } from '../../actions/dashboard.action';
// import { useDispatch, useSelector } from "react-redux"

Chart.register([
    Tooltip, {
        id: "paymentsTotal",
        afterDraw: chart => {
            // console.log(chart, "afterDraw");
            if (chart.tooltip?._active?.length && chart.id === 3) {
                let x = chart.tooltip._active[0].element.x;
                let yAxis = chart.scales.y;
                let ctx = chart.ctx;
                ctx.save();
                ctx.beginPath();
                ctx.setLineDash([8, 8]);
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = 'gray';
                ctx.stroke();
                ctx.restore();
            }
        }
    }
], LineController, LineElement, PointElement, LinearScale, Title,
    Filler // Filler for background gradient
);

const PaymentChart = ({ title, action, chartData, getLabels, getData }) => {
    const [isActive, setActive] = useState("7");
    // const [xAxisLabel, setXAxisLabel] = useState("Dates of the month");
    const [chartDataList, setChartDataList] = useState([]);
    // console.log(chartData, "chartData");
    useEffect(() => {
        if (isActive === "7") {
            setChartDataList(chartData.data.slice(0, 7));
        } else if (isActive === "30") {
            setChartDataList(chartData.data.slice(0, 30));
        } else if (isActive === "YTD") {
            setChartDataList(chartData.data);
        }
    }, [chartData, isActive])
    const data = {
        // labels: ["27/5", "31/5", "04/6", "08/6", "12/6", "16/6", "20/6", "24/6"],
        // labels: ["27/5", "28/5", "29/5", "30/5", "31/5", "01/6", "02/6", "03/6", "04/6", "05/6", "06/6", "07/6", "08/6", "09/6", "10/6", "11/6", "12/6", "13/6", "14/6", "15/6", "16/6", "17/6", "18/6", "19/6", "20/6", "21/6", "22/6", "23/6", "24/6", "25/6", "26/6", "27/6"],
        datasets: [
            {
                label: "Payment",
                // data: [2500, 5000, 6500, 10000, 4000, 5200, 5000, 6000, 3500, 3800, 5200, 5200, 5000, 6000, 3500, 3800, 5200, 6500, 10000, 4000, 5200, 5000, 6000, 3500, 3800, 5200, 5200, 5000, 6000, 3500, 3800, 5200, 3500, 3800, 5200, 6500, 10000, 4000, 5200, 5000, 6000, 3500, 3800, 5200, 5200, 5000, 6000, 3500, 3800, 5200],
                data: chartDataList,
                parsing: {
                    xAxisKey: 'date',
                    yAxisKey: 'payments'
                },
                borderColor: [
                    '#4CE748',
                ],
                borderWidth: 5,
                fill: true,
                // background: [
                //     'red',
                // ],
                pointRadius: 0,
                pointHoverRadius: 8,
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 250);
                    gradient.addColorStop(0, "rgba(76, 231, 72, .8)");
                    gradient.addColorStop(.8, "rgba(87, 255, 82, 0)");
                    return gradient;
                },//background gradient color
                pointBackgroundColor: "#4CE748",
            }
        ],
        showLine: true,
    }
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {

                intersect: false,
                displayColors: false,
                backgroundColor: "#fff",
                color: "#000",
                titleColor: "#43AA40",
                borderColor: "#ccc",
                borderWidth: 1,
                // titleColor: (context) => {
                //     return context.tooltip.labelTextColors[0]
                // },
                callbacks: {
                    color: () => "#000",
                    labelTextColor: () => '#43AA40',
                    // label: (context) => {
                    //     return context.raw;
                    // },
                    // beforeDraw: (Chart) => {
                    //     const ctx = Chart.ctx;
                    //     console.log(ctx, "ctx");
                    // },
                    // custom: (tooltip) => {
                    //     console.log(tooltip, "tooltip");
                    //     let tooltipEl = document.getElementById("chartjs-tooltip");
                    //     if (!tooltipEl) {
                    //         tooltipEl = document.createElement("div");
                    //         tooltipEl.id = "chartjs-tooltip";
                    //         this._chart.canvas.parentNode.appendChild(tooltipEl);
                    //     }
                    //     console.log(tooltip, "tooltip");
                    //     ReactDOM.render(<CustomTooltip tooltip={tooltip} chart={this._chart} />, tooltipEl);
                    // }
                },
                enable: true,
            },


        },
        interaction: {
            intersect: false,
        },
        scales: {

            x: {
                display: true,
                type: "time",
                time: {
                    unit: () => {
                        if (isActive === "YTD") {
                            return "month";
                        }
                        return "day"
                    },
                    displayFormats: () => {
                        if (isActive === "7") {
                            return {
                                "day": "E",
                            };
                        } else if (isActive === "30") {
                            return {
                                "day": "dd MMM",
                            };
                        }
                        else if (isActive === "YTD") {
                            return {
                                "month": "MMM y",
                            };
                        }

                    }
                },
                title: {
                    display: true,
                    text: () => {
                        if (isActive === "7") {
                            return "Days of week";
                        } else if (isActive === "30") {
                            return "Dates of the month";
                        }
                        else if (isActive === "YTD") {
                            return "Monts of the year";
                        }
                    },
                    align: "end",
                    color: "#000",
                }
            },
            y: {
                display: true,
                title: {
                    display: true,
                    // labels: [1000, 2500, 5000, 7500, 10000],
                    text: 'Amount in Rupees',
                    align: "start",
                    color: "#000",
                },
                suggestedMin: 0,
                // suggestedMax: 12000,
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (num, index, ticks) {
                        return nFormatter(num);
                    }
                }
            }
        }
    }
    // const dispatch = useDispatch();
    // console.log(action, "props  ")
    // useEffect(() => {
    //     action?.();
    // }, []);
    // console.log(chartData, "api response");
    const handleChange = (e) => {
        // console.log(e.target.value, e.target.name);
        setActive(e.target.value)
        // if (isActive === "7") {
        //     setChartDataList(chartData.data.slice(0, 7));
        //     // setXAxisLabel("Days of week")
        // } else if (isActive === "30") {
        //     setChartDataList(chartData.data.slice(0, 30));
        //     // setXAxisLabel("Dates of the month")
        // } else if (isActive === "YTD") {
        //     setChartDataList(chartData.data);
        //     // setXAxisLabel("Months of the year")
        // }
        // action?.(e.target.value)
    };
    const inputList = [
        {
            id: 1,
            label: "Last 7 days",
            value: "7",
        },
        {
            id: 2,
            label: "Last 30 days",
            value: "30",
        },
        {
            id: 3,
            label: "YTD",
            value: "YTD",
        }
    ]
    // console.log(chartData?.error.message, "chart error")

    return (
        <div className="chartBox-container" >

            {/* <div className="chartBlock-title">{title}</div> */}
            <div className="chart-inputGroup" style={{ display: 'flex', gap: 15 }} >
                {
                    inputList.map(({ id, label, value }) => (
                        <div key={id}>
                            <input type="radio" name={title} value={value}
                                id={title + value}
                                onChange={handleChange}
                                checked={isActive === value} />
                            <label htmlFor={title + value}>{label}</label>
                        </div>
                    ))
                }
            </div >

            <div style={{ position: "relative", display: "grid" }}>
                {chartData?.loading && <div className='loader-container'>
                    <div className="loader"></div>
                </div>}
                {chartData?.error && <div className="loader-container">Something went wrong</div>}
                <div style={{ display: "flex", paddingTop: 10 }}>
                    {/* <div className="chartVertical-label">Amount in Rupees</div> */}
                    <div style={{ flex: 1 }}>
                        <Line datasetIdKey='paymentsTotal' data={data} options={options} />
                    </div>
                </div>
                {/* <span className='chartHorizontal-label'>Dates of the month</span> */}
            </div>

        </div >
    )
}

export default PaymentChart
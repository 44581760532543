import { userLoginTypes, userBearerTokenTypes } from "../types";

export function login(val) {
    return { type: userLoginTypes.FETCH, payload: val };
}
export function loginSuccess(val) {
    return { type: userLoginTypes.SUCCESS, payload: val };
}
export function loginError(val) {
    return { type: userLoginTypes.ERROR, payload: val };
}

export function loginReset(val) {
    return { type: userLoginTypes.RESET };
}

export function getBearerToken(data) {
    return { type: userBearerTokenTypes.FETCH, payload: data };
}
export function bearerTokenSuccess(val) {
    return { type: userBearerTokenTypes.SUCCESS, payload: val };
}
export function bearerTokenError(val) {
    return { type: userBearerTokenTypes.ERROR, payload: val };
}

export function bearerTokenReset(val) {
    return { type: userBearerTokenTypes.RESET };
}
const loginActions = {
    login,
    loginSuccess,
    loginError,
    loginReset,
    getBearerToken,
    bearerTokenSuccess,
    bearerTokenError,
    bearerTokenReset,
};
export default loginActions;

import React, {
    useEffect,
    // useEffect,
    useState
} from 'react'
import { Line } from 'react-chartjs-2'
// import { Chart as ChartJS } from "chart.js/auto";
import { Chart, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, Tooltip, Legend, TimeScale } from 'chart.js';
// import { getLast7daysName } from '../../utils';
// import { formatDate } from '../../CommonUtils/commonUtils';
import "chartjs-adapter-date-fns"
// import { dashboardPanelistFetch } from '../../actions/dashboard.action';
// import { useDispatch, useSelector } from "react-redux"
Chart.register([Tooltip, {
    id: "addition",
    afterDraw: function (chart, easing) {
        // console.log(easing, "type of config")
        if (chart.tooltip?._active?.length) {
            let x = chart.tooltip._active[0].element.x;
            let yAxis = chart.scales.y;
            let ctx = chart.ctx;
            ctx.save();
            ctx.beginPath();
            ctx.setLineDash([8, 8]);
            ctx.moveTo(x, yAxis.top);
            ctx.lineTo(x, yAxis.bottom);
            ctx.lineWidth = 1;
            ctx.strokeStyle = 'gray';
            ctx.stroke();
            ctx.restore();
        }
    }
}],
    LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, Legend, TimeScale
);



const inputList = [
    {
        id: 1,
        label: "Last 7 days",
        value: "7",
    },
    {
        id: 2,
        label: "Last 30 days",
        value: "30",
    },
    {
        id: 3,
        label: "YTD",
        value: "YTD",
    }
]
// const getDayName = (date) => {
//     let weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
//     return weekday[new Date(date).getDay()]
// }
// const getMonthName = (date) => {
//     const months = [
//         'January',
//         'February',
//         'March',
//         'April',
//         'May',
//         'June',
//         'July',
//         'August',
//         'September',
//         'October',
//         'November',
//         'December',
//     ];
//     return months[new Date(date).getMonth()];
// }
// const getSevenDays = (data) => {
//     const days7 = data.slice(0, 7);
//     return days7.map(data => getDayName(data.date))
// }

// const getLast30days = () => {

//     var goBackDays = 30;

//     var today = new Date();
//     var daysSorted = [];

//     for (var i = goBackDays - 1; i >= 0; i--) {
//         // var newDate = new Date(today.setDate(today.getDate() - i));
//         var newDate = new Date(new Date().setDate(today.getDate() - i));
//         daysSorted.push(formatDate(newDate));
//     }

//     // for (var i = 0; i < goBackDays; i++) {
//     //     var newDate = new Date(today.setDate(today.getDate() - 1));
//     //     daysSorted.push(formatDate(newDate));
//     // }
//     return daysSorted;
// }

const MultiLineChart = ({ action, chartData, getLabels, getData }) => {

    // const dispatch = useDispatch();
    // console.log(action, "props  ")
    // useEffect(() => {
    //     action?.();
    // }, []);
    // console.log(chartData, "api response");
    // const chartRef = useRef(null);

    const [isActive, setActive] = useState("7");
    // const initialLabelData = ["27/5", "28/5", "29/5", "30/5", "31/5", "01/6", "02/6", "03/6", "04/6", "05/6", "06/6", "07/6", "08/6", "09/6", "10/6", "11/6", "12/6", "13/6", "14/6", "15/6", "16/6", "17/6", "18/6", "19/6", "20/6", "21/6", "22/6", "23/6", "24/6", "25/6", "26/6", "27/6"];
    // const initialLabelData = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    // const initialLabelData = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // const [labelsData, setLabelData] = useState(initialLabelData);
    const [chartDataList, setChartDataList] = useState([]);

    useEffect(() => {
        if (isActive === "7") {
            setChartDataList(chartData.data.slice(0, 7));
        } else if (isActive === "30") {
            setChartDataList(chartData.data.slice(0, 30));
        } else if (isActive === "YTD") {
            setChartDataList(chartData.data);
        }
    }, [chartData, isActive])
    // console.log(chartDataList, "chartDataList");
    // const initialPanelistDataset = [150, 320, 360, 900, 700, 50, 800, 150, 320, 360, 900, 700, 50, 800, 150, 320, 360, 900, 700, 50, 800]
    // const [panelistDataset, setPanelistDataset] = useState(initialPanelistDataset);

    // const initialLeadsDataset = [300, 750, 600, 1000, 320, 800, 1000]
    // const [leadsDataset, setLeadsDataset] = useState(initialLeadsDataset);

    const [xAxisLabel, setXAxisLabel] = useState("Days of Week");

    const handleChange = (e) => {
        // console.log("value", e.target.value, "name", e.target.name,);
        setActive(e.target.value);
        // action?.(e.target.value);
        if (e.target.name === "7addition") {
            // LABELS
            // setLabelData(getLast7daysName())

            //DATASETS
            //PANELISTS
            // const newPanelistDataset = chartData.data.slice(0, 7).map((set) => set.panelists)
            // setPanelistDataset(newPanelistDataset);
            // //LEADS
            // const newLeadsDataset = chartData.data.slice(0, 7).map((set) => set.leads)
            // setLeadsDataset(newLeadsDataset);

            // console.log("newPanelistDataset", newPanelistDataset);
            // console.log(newLabel, days7, "newLabel");

            setChartDataList(chartData.data.slice(0, 7))
            setXAxisLabel("Days of week");
        } else if (e.target.name === "30addition") {
            // LABELS DATE
            // const newLabel = chartData.data.slice(0, 30).map(data => data.date).reverse();
            // const newLabel = getLast30days();
            // setLabelData(newLabel);
            // console.log(newLabel, "30 addition");

            setChartDataList(chartData.data.slice(0, 30))
            // console.log(getLast30days(), "last 30 days")

            // DATASETS
            //PANELISTS
            // const newPanelistDataset = chartData.data.slice(0, 30).map((set) => set.panelists);
            // setPanelistDataset(newPanelistDataset);

            // LEADS
            // const newLeadsDataset = chartData.data.slice(0, 30).map((set) => set.leads)
            // setLeadsDataset(newLeadsDataset);

            setXAxisLabel("Dates of the month");

        } else if (e.target.name === "YTDaddition") {
            // const newLabel = [...new Set(chartData.data.map(data => getMonthName(data.date)))];
            // const newLabel = chartData.data.map(data => getMonthName(data.date)).reverse();
            // setLabelData(newLabel);
            // console.log(newLabel, "newLabel ", e.target.name);
            // const newPanelistDataset = chartData.data.map((set) => set.panelists)
            // setPanelistDataset(newPanelistDataset);

            setXAxisLabel("Months of the year");
            setChartDataList(chartData.data.slice(0, 366))
        }
    };

    // console.log(chartData?.error.message, "chart error")
    const data = {
        // labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        // labels: labelsData,
        // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
            {
                label: "Panelists",
                data: chartDataList,
                // data: chartData.data.slice(0, 7).reverse().map((data) => ({ x: format(new Date(data.date), "E"), y: data.panelists })),
                // data: ,
                backgroundColor: [
                    '#4169D2',
                ],
                borderColor: [
                    '#4169D2',
                ],
                borderWidth: 3,
                pointRadius: 4,
                pointHoverRadius: 8,
                parsing: {
                    xAxisKey: 'date',
                    yAxisKey: 'panelists'
                },
            },
            {
                label: "Leads",
                data: chartDataList,
                // data: chartData.data.slice(0, 7).reverse().map((data) => ({ x: format(new Date(data.date), "E"), y: data.leads })),
                // data: ,
                backgroundColor: [
                    '#EB008A',
                ],
                borderColor: [
                    '#EB008A',
                ],
                borderWidth: 3,
                pointRadius: 4,
                pointHoverRadius: 8,
                parsing: {
                    xAxisKey: 'date',
                    yAxisKey: 'leads'
                },
            }
        ]
    }
    const options = {
        interaction: {
            intersect: false,
        },
        plugins: {
            legend: {
                // display: false,
                fullSize: true,
                position: "bottom",
                align: "start",
                padding: 100,
                pointStyle: "circle",
                labels: {
                    usePointStyle: true,
                    pointStyle: "circle",
                    boxWidth: 5,
                    font: {
                        size: 16,
                    }
                },
                title: {
                    padding: 10,
                    color: "#000",
                }
            },
            tooltip: {
                backgroundColor: "#fff",
                titleFont: {
                    size: "14px",
                    align: "center",
                },
                borderColor: "#ccc",
                borderWidth: 1,
                usePointStyle: false,
                displayColors: false,
                titleColor: (context) => {
                    return context.tooltip.labelTextColors[0]
                },
                callbacks: {
                    labelTextColor: (context) => {
                        // console.log(context, "labeltext color")
                        return context.dataset.backgroundColor[0]
                    },

                    // labelPointStyle: function (context) {
                    //     return {
                    //         pointStyle: 'triangle',
                    //         rotation: 0
                    //     };
                    // },
                    // label: (context) => {
                    //     return context.label;
                    // },
                },
            },
        },
        scales: {
            x: {
                display: true,
                type: "time",
                time: {
                    unit: () => {
                        if (isActive === "7") {
                            return "day"
                        } else if (isActive === "YTD") {
                            return "month";
                        }
                    },
                    displayFormats: () => {
                        if (isActive === "7") {
                            return {
                                "day": "E",
                            };
                        } else if (isActive === "30") {
                            return {
                                "day": "dd MMM",
                            };
                        }
                        else if (isActive === "YTD") {
                            return {
                                "month": "MMM y",
                            };
                        }

                    }
                },
                title: {
                    display: true,
                    text: xAxisLabel,
                    align: "end",
                    color: "#000",
                },
                // reverse: true,
            },
            y: {

                display: true,
                title: {
                    display: true,
                    text: 'Number Added',
                    align: "start",
                    color: "#000",
                },
                // suggestedMin: 0,
            },


        }
    }

    return (
        <div className="chartBox-container">
            {/* <div className="chartBlock-title">{title}</div> */}
            <div className="chart-inputGroup" style={{ display: 'flex', gap: 15 }}>
                {inputList.map(({ id, label, value }) => (
                    <div key={id}>
                        <input type="radio" name={value + "addition"} value={value}
                            id={"addition" + value}
                            onChange={handleChange}
                            checked={isActive === value} />
                        <label htmlFor={"addition" + value}>{label}</label>
                    </div>
                ))}
            </div>

            <div style={{ position: "relative", display: "grid" }}>
                {chartData?.loading && <div className='loader-container'>
                    <div className="loader"></div>
                </div>}
                {chartData?.error && <div className="loader-container">Something went wrong</div>}
                <div style={{ display: "flex", paddingTop: 10 }}>
                    {/* <div className="chartVertical-label">Number Added</div> */}
                    <div style={{ flex: 1 }}>
                        {/* CHARTJS */}
                        <Line datasetIdKey='addition' data={data} options={options} />
                    </div>
                </div>
                {/* <span className='chartHorizontal-label'>Days of Week</span> */}
                {/* <div style={{ display: "flex", paddingLeft: 30, gap: 15 }}>
                    <div>
                        <ColorLabel color={"#4169D2"} >Panelists Added</ColorLabel>
                    </div>
                    <div>
                        <ColorLabel color={"#E04573"} >Leads Added</ColorLabel>
                    </div>
                </div> */}
            </div>

        </div >
    )
}

export default MultiLineChart
import { promotionListTypes } from "../types";

export function promotionListFetch() {
    return {
        type: promotionListTypes.FETCH
    }
}
export function promotionListSuccess(val) {
    return {
        type: promotionListTypes.SUCCESS,
        payload: val
    }
}
export function promotionListError(val) {
    return {
        type: promotionListTypes.ERROR,
        payload: val
    }
}
export function promotionListReset() {
    return {
        type: promotionListTypes.RESET
    }
}


import React, { useState, useEffect } from 'react';
import "../../Components/ApproveRejectButton/ApproveModal.css";
import approvedsvg from "../../Assets/Images/approvedsvg.png";
import axios from 'axios';
import { urls } from "../../CommonUtils/config";
import {
    clearDataFromStore,
    getDataFromStore,
} from "../../CommonUtils/commonUtils";
import "../../CommonUtils/api";

const ApproveModal = ({ onClose, requestid, onSubmitError }) => {
    const storedToken = localStorage.getItem("authToken");

    const [validateStatus, setValidateStatus] = useState("A");
    const [validateBy, setValidateBy] = useState("");
    const [validateRemarks, setValidateRemarks] = useState("");
    const [fullName, setFullName] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    console.log(requestid)
    const performPutRequest = async (payload) => {
        try {

            console.log('Payload:', payload);

            const response = await axios.put(
                `${urls.engageBaseUrl}SMPayment/UpdatesSMPaymentRequestApproveRejectFromEngageBackOffice/${requestid}`,
                payload, {
                headers: {

                    'Authorization': `Bearer ${storedToken}`,

                    'Content-Type': 'application/json',

                }
            }
            );


            console.log('Response:', response.data.data);

        } catch (error) {
            console.error('Error:', error);


            onSubmitError();
            onClose(false);
            setErrorMessage('Error occurred while submitting the request. Please try again later.');
        }

    };

    const handleApproveSubmit = async () => {
        const payload = {
            validateStatus: validateStatus,
            validateBy: validateBy,
            validateRemarks: validateRemarks,
            requestid: requestid
        };


        await performPutRequest(payload);
        onClose(false);
        window.location.reload();
    };

    useEffect(() => {
        try {
            const { user: { firstName, lastName } } = JSON.parse(getDataFromStore("userData"));
            setValidateBy(firstName + " " + lastName);
        } catch (e) {
            setValidateBy(null);
        }
    }, [])

    console.log(fullName, "Fulllll Nameeee")

    return (
        <div className='modal-container'>
            <div className="approve-modal">
                <div className="modal-content">
                    <img src={approvedsvg} alt="Logo" className='imagapprove' />
                    <p className='statusApp'>Status: Approved</p>

                    <input
                        type="text"
                        className="placeholder"
                        placeholder="Please enter remarks"
                        value={validateRemarks}

                        onChange={(e) => setValidateRemarks(e.target.value)}
                    />
                    <div className='buttonalign'>
                        <button className='buttonyess' validateStatus="A" onClick={handleApproveSubmit}>Submit</button>
                        <button className='button' onClick={() => onClose(false)}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApproveModal;

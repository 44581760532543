import { dataCollectionDetailsTypes } from "../types";
const initialState = {
    data: {},
    loading: false,
    error: "",

}
const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case dataCollectionDetailsTypes.FETCH:
            return { ...state, loading: true }
        case dataCollectionDetailsTypes.SUCCESS:
            return { ...state, data: payload, loading: false, error: "", }
        case dataCollectionDetailsTypes.ERROR:
            return { ...state, loading: false, error: payload }
        case dataCollectionDetailsTypes.RESET:
            return initialState;
        default:
            return state;
    }
}

export default reducer;
import { call, put, takeLatest } from "redux-saga/effects";
import {
    // dashboardAssignmentsTypes,
    dashboardLeadsTypes, dashboardPanelistTypes, dashboardPaymentsTypes, dashboardTotalDataTypes
} from "../types";
import {
    // getDashboardAssignments,
    getDashboardLeads,
    // getDashboardPanelists,
    getDashboardPanelistsAndLeads, getDashboardPayments, getDashboardTotalData
} from "../services/dashboard.api";
import {
    // dashboardAssignmentsError, dashboardAssignmentsSuccess,
    dashboardLeadsError, dashboardLeadsSuccess, dashboardPanelistError, dashboardPanelistSuccess, dashboardPaymentsError, dashboardPaymentsSuccess, dashboardTotalDataError,
    // dashboardTotalDataFetch,
    dashboardTotalDataSuccess
} from "../actions/dashboard.action";

function* fetchDashboardPanelist() {
    try {
        // const dashboardPanelists = yield call(getDashboardPanelists);
        const dashboardPanelistsAndLeads = yield call(getDashboardPanelistsAndLeads);
        // yield put(dashboardPanelistSuccess(dashboardPanelists.data.slice(0, 10)));
        yield put(dashboardPanelistSuccess(dashboardPanelistsAndLeads));
    } catch (e) {
        yield put(dashboardPanelistError(e));
    }
}
function* fetchDashboardLeads() {
    try {
        const dashboardLeads = yield call(getDashboardLeads);
        yield put(dashboardLeadsSuccess(dashboardLeads.data.slice(0, 10)));
    } catch (e) {
        yield put(dashboardLeadsError(e));
    }
}
// function* fetchDashboardAssignments() {
//     try {
//         const dashboardAssignments = yield call(getDashboardAssignments);
//         yield put(dashboardAssignmentsSuccess(dashboardAssignments.data.slice(0, 10)));
//     } catch (e) {
//         yield put(dashboardAssignmentsError(e));
//     }
// }
function* fetchDashboardPayments({ payload }) {
    try {
        const dashboardPayments = yield call(getDashboardPayments, payload);
        // console.log(dashboardPayments)
        yield put(dashboardPaymentsSuccess(dashboardPayments));
    } catch (e) {
        console.log(e, "dashboardPaymentsError");
        yield put(dashboardPaymentsError(e));
    }
}
function* fetchTotalData({ payload }) {
    try {
        const dashboardTotalData = yield call(getDashboardTotalData, payload);
        // console.log(dashboardTotalData)
        yield put(dashboardTotalDataSuccess(dashboardTotalData.data));
    } catch (e) {
        console.log(e, "dashboardTotalData");
        yield put(dashboardTotalDataError(e));
    }
}


export default function* saga() {
    yield takeLatest(dashboardPanelistTypes.FETCH, fetchDashboardPanelist);
    yield takeLatest(dashboardLeadsTypes.FETCH, fetchDashboardLeads);
    // yield takeLatest(dashboardAssignmentsTypes.FETCH, fetchDashboardAssignments);
    yield takeLatest(dashboardPaymentsTypes.FETCH, fetchDashboardPayments);
    yield takeLatest(dashboardTotalDataTypes.FETCH, fetchTotalData);
}

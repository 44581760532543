import { eClinicListTypes } from "../types";

export function eClinicListFetch() {
    return {
        type: eClinicListTypes.FETCH
    }
}
export function eClinicListSuccess(val) {
    return {
        type: eClinicListTypes.SUCCESS,
        payload: val
    }
}
export function eClinicListError(val) {
    return {
        type: eClinicListTypes.ERROR,
        payload: val
    }
}
export function eClinicListReset() {
    return {
        type: eClinicListTypes.RESET
    }
}


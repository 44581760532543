import { getDataFromStore } from "../CommonUtils/commonUtils";
import { userLoginTypes, userBearerTokenTypes } from "../types";
import { urls } from '../CommonUtils/config';
const basicUsername = urls.basicUsername
const basicPassword = urls.basicPassword
const grant_type = urls.grant_type
const userName = urls.userName
const password = urls.password

const initialState = {
    authUserName: basicUsername,
    authPassword: basicPassword,
    username: userName,
    password: password,
    grant_type: grant_type,
    basic_token: "Y3VyZWJheTpjR0Z6YzNkdmNtUT0=",
    loggedIn: getDataFromStore("loggedIn"),
    isTokenSet: false,
    data: null,
    loading: false,
    error: "",
    channel: "W",
    isUserValid: null
}
const reducer = (state = initialState, action) => {

    // console.log('----------------------');

    const { type, payload } = action;
    switch (type) {
        case userBearerTokenTypes.FETCH:
            return { ...state, loading: true }
        case userLoginTypes.SUCCESS:
            return { ...state, loading: false, loggedIn: true, error: "", data: payload, isUserValid: true }
        case userBearerTokenTypes.SUCCESS:
            return { ...state, isTokenSet: true, loading: false, error: "" }
        case userLoginTypes.ERROR:
            return { ...state, loading: false, loggedIn: false, error: "", isUserValid: false, isTokenSet: false }
        case userBearerTokenTypes.ERROR:
            return { ...state, loading: false, error: payload, isTokenSet: false }
        case userBearerTokenTypes.RESET:
            return initialState;
        default:
            return state;
    }
}

export default reducer;
import React, { useEffect, useRef, useState } from 'react'
import "./IconDropDown.css"

const IconDropDown = ({ list, onChange, activeOption }) => {
    const [isDropDownOpen, setDropDownOpen] = useState(false);
    const inputContainer = useRef(null)
    const listContainer = useRef(null)

    const handleOptionClick = (option) => {
        setDropDownOpen(false)
        onChange(option)
    }
    const handleInputClick = (e) => {

        // const listContainerTarget = listContainer.current;
        // if (e.clientY > 300) {
        //     console.log("top")
        //     listContainerTarget?.classList.add("iconDropDownOptiontop")
        // } else {
        //     console.log("bottom")
        //     listContainerTarget?.classList.remove("iconDropDownOptiontop")
        // }
        setDropDownOpen(!isDropDownOpen)
    }

    useEffect(() => {
        const handleOutSideClick = (e) => {
            if (!inputContainer?.current?.contains(e.target)) {
                setDropDownOpen(false)
            }
        }
        if (isDropDownOpen) {
            window.addEventListener("mousedown", handleOutSideClick)
        } else {
            window.removeEventListener("mousedown", handleOutSideClick)
        }

    }, [isDropDownOpen])
    return (
        <div ref={inputContainer} style={{ position: "relative", width: 466 }} onClick={(e) => e.stopPropagation()} >
            <div className='iconDropDownInput' onClick={handleInputClick}>
                <img src={` https://flagcdn.com/w80/${activeOption.code.toLowerCase()}.png`} style={{ top: 13, left: 15, width: 30, height: 30, borderRadius: "50%" }} className="profilePage-inputIcon" alt='Selected Flag' />
                {activeOption.name}
            </div>
            {isDropDownOpen && <div ref={listContainer} className='iconDropDownOptionsContainer ' onClick={(e) => e.stopPropagation()}>
                {list.map(({ name, code }) => (
                    <div key={code}
                        className="iconDropDownListContainer"
                        style={{ ...(activeOption.code === code && { backgroundColor: "#004171", cursor: "unset", color: "#fff" }) }}
                        {...(activeOption.code !== code && { onClick: () => handleOptionClick({ name, code }) })}
                    >
                        <img src={` https://flagcdn.com/w80/${code.toLowerCase()}.png`} alt="Country Flag icon" />
                        {name}
                    </div>))}
            </div>}
        </div>
    )
}

export default IconDropDown
import { call, put, takeEvery } from "redux-saga/effects";
import { eClinicListError, eClinicListSuccess } from "../actions/eClinicList.action";

import { getEClinicList } from "../services/api"
import { eClinicListTypes } from "../types";

function* fetchEClinicList() {
    try {
        const eClinicList = yield call(getEClinicList);
        yield put(eClinicListSuccess(eClinicList));
    } catch (e) {
        yield put(eClinicListError(e));
    }
}

export default function* saga() {
    yield takeEvery(eClinicListTypes.FETCH, fetchEClinicList);
}
